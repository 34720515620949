import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, change, Field } from 'redux-form';
import moment from 'moment';

import { FILTER_FORM } from 'constants';

import RenderDatePicker from 'components/Form/RenderDatePicker';

function Filter({dispatch, handleSubmit, types, filters, onSubmit, onDelete, type}) {
    let filtersKeys = [];
    if (filters) {
        filtersKeys = Object.keys(filters);
    }

    const isDate = (type === 'created_at' || type === 'start_at');

    return (
        <div className="p-3">
            {filtersKeys.length !== 0 && (
                <div className="mb-2">
                    {filtersKeys.map((filter, index) => (
                        <div
                            key={index} 
                            className="d-inline-block p-2 py-2 mr-1 mt-1 badge badge-pill badge-secondary"
                        >
                            {types[filter]} : &nbsp;
                            {filters[filter]}
                            <span 
                                className="ml-2" 
                                onClick={() => {
                                    onDelete(filter);
                                }}
                            >
                                &times;
                            </span>
                        </div>
                    ))}
                </div>
            )}
            Ajouter un filtre :
            <div className="mt-2">
                <Field 
                    name="type"
                    component="select"
                    className="form-control w-100"
                    onChange={() => dispatch(change(FILTER_FORM, 'value', ''))}
                >
                    {Object.keys(types).map(filterType => (
                        <option key={filterType} value={filterType}>
                            {types[filterType]}
                        </option>
                    ))}
                </Field>
            </div>
            <div className="mt-1">
                {isDate ? (
                    <Field 
                        name="value" 
                        component={RenderDatePicker} 
                        className="form-control w-100"
                    />
                ) : (
                    <Field 
                        name="value" 
                        component="input" 
                        type="text" 
                        className="form-control w-100"
                    />
                )}
            </div>
            <div className="text-center mt-3">
                <button 
                    className="btn btn-secondary"
                    onClick={handleSubmit((values) => {
                        if (isDate) {
                            values.value = moment(values.value).format("YYYY-MM-DD");
                        }
                        onSubmit(values);
                    })}
                >
                    Ajouter
                </button>
            </div>
        </div>
    );
};

Filter.displayName = 'Filter';

Filter.propTypes = {
};

Filter = reduxForm({
    form: FILTER_FORM,
    validate: (values) => {
        if (!values.type || !values.value) {
            return {type: 'Obligatoire', value: 'Obligatoire'}
        }
    }
})(Filter);

const selector = formValueSelector(FILTER_FORM);
export default connect(state => ({type: selector(state, 'type')}))(Filter);
