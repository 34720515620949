import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {VictoryChart, VictoryLine, VictoryTheme, VictoryLegend, VictoryAxis} from 'victory';
import { get } from 'api';

import { 
    getAllDeskEM, 
    addFilter,
    deleteFilter,
} from 'actions/index.js';
import history from '../../history';
import {usePagination} from '../../hooks';

import ScreenList from 'components/Screen/List';
import Row, {RowHeader} from 'components/Dashboard/DeskEM/Row';

function mapStateToProps(state) {
    return {
        token: state.auth.data.token,
        deskems: state.deskems,
        filters: state.filters,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAll: (...args) => dispatch(getAllDeskEM(...args)),
        addFilter: (...args) => dispatch(addFilter(...args)),
        deleteFilter: (...args) => dispatch(deleteFilter(...args)),
    };
}

function DeskEMList({match, token, deskems, filters, getAll, addFilter, deleteFilter}) {
    const [perfs, setPerfs] = useState([])
    usePagination(() => getAll(token, window.location.search));

    useEffect(() => {
        get("desk_ems/perfs", token).then((res) => setPerfs(res.data))
    }, [])
    
    return (
        <ScreenList
            title="Analyses Desk EM"
            filter={{
                data: filters,
                types: {
                    qr_code: 'QR Code',
                    client_group: 'Créateur',
                    raspberry_name: 'Raspberry',
                    created_at: 'Date'
                },
                add: (values) => addFilter(values),
                delete: (filter) => deleteFilter(filter),
            }}
            data={deskems.data}
            renderHeader={(props) => <RowHeader {...props} />}
            renderRow={({item}) =>
                <Row 
                    data={item}
                    onClick={(item) => history.push(`${match.url}/${item.id}`)}
                    match={match}
                    history={history}
                />
            }
            renderList={(list) =>
                <React.Fragment>
                    <div className="border-bottom px-3 pt-3">
                        <div className="mb-2">Performances</div>
                        <VictoryChart theme={VictoryTheme.material} height="200" width="1000">
                            <VictoryLegend x={480} y={0}
                                orientation="horizontal"
                                gutter={10}
                                style={{ labels: {fontSize: 8 } }}
                                data={[
                                    {name: "Irreg", symbol: {fill: "#003f5c" }},
                                    {name: "Sale", symbol: {fill: "#ffa600" }}
                                ]}
                            />
                            <VictoryAxis style={{tickLabels: { fontSize: 7 }}} dependentAxis />
                            <VictoryAxis style={{tickLabels: { fontSize: 7 }}} />
                            <VictoryLine
                                style={{
                                    data: { stroke: "#003f5c" },
                                    parent: { border: "1px solid #ccc"}
                                }}
                                data={perfs}
                                x="time"
                                y="sale_difference"
                            />
                            <VictoryLine
                                style={{
                                    data: { stroke: "#ffa600" },
                                    parent: { border: "1px solid #ccc"}
                                }}
                                data={perfs}
                                x="time"
                                y="irreg_difference"
                            />
                        </VictoryChart>
                    </div>
                    {list}
                </React.Fragment>
            }
        />
    );
};

DeskEMList.displayName = 'DeskEMList';

DeskEMList.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskEMList);
