export const NOTIFICATION_ADDED = 'NOTIFICATION_ADDED';
export const NOTIFICATION_DELETED = 'NOTIFICATION_DELETED';
export const FILTER_RESET = 'FILTER_RESET';
export const FILTER_ADDED = 'FILTER_ADDED';
export const FILTER_DELETED = 'FILTER_DELETED';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const DESKMW = 'DESKMW';
export const DESKEM = 'DESKEM';
export const DESKTHICKNESS = 'DESKTHICKNESS';
export const DESKDRAINBACK = 'DESKDRAINBACK';
export const CLEARDRAIN = 'CLEARDRAIN';
export const SEEPAGE = 'SEEPAGE';
export const MILEAGE = 'MILEAGE';

export const PREDICT = 'PREDICT';
export const RASPBERRIES = 'RASPBERRIES';
export const RASPBERRY = 'RASPBERRY';
export const NANOS = 'NANOS';
export const NANO = 'NANO';
export const CONTACT = 'CONTACT';
