import React from 'react';
import PropTypes from 'prop-types';

import {getURLParam, setURLParam} from 'helpers';

import Caret from 'components/Icons/Caret';
import CaretDouble from 'components/Icons/CaretDouble';

/**
 * SortableColumn Functional Component.
 */
export default function SortableColumn({name, children, className=""}) {
    const sortBy = getURLParam("sort_by");
    const sortOrder = sortBy ? sortBy.charAt(0) : null;

    return (
        <div className={className}>
            {children}

            <div
                className="d-inline-block ml-2 sortable" 
                onClick={() => {
                    if (!name) {
                        return;
                    }

                    if (sortOrder === "-") {
                        setURLParam('sort_by', name);
                    } else {
                        setURLParam('sort_by', "-" + name);
                    }
                }}
            >
                {sortBy && sortBy.endsWith(name)
                    ? (
                        <Caret 
                            style={{transform: `rotate(${sortOrder === '-' ? '180' : '0'}deg)`}}
                        />
                    ) : (
                        <CaretDouble />
                    )
                }
            </div>
        </div>
    );
};

SortableColumn.displayName = 'SortableColumn';

SortableColumn.propTypes = {
};
