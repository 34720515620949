import React from 'react';

const Error404 = () => {
    return (
        <div className="404 d-flex justify-content-center align-items-center h-100">
            <div className="col-md-8">
                <h1>404</h1>
                <h3>L'adresse que vous demandez est introuvable.</h3>
                <p>
                    La page que vous avez demandée n’a pas été trouvée. Il se peut que le lien que 
                    vous avez utilisé soit rompu ou que vous ayez tapé l’adresse (URL) 
                    incorrectement.
                </p>
                <a href="/">Retourner à la page d'accueil</a>
            </div>
        </div>
    );
};

export default Error404;
