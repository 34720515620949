import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useForm} from "react-hook-form";
import classnames from 'classnames';

import { 
    addNotification,
    getNanos,
    mileage
} from 'actions';
import {timeIntegersToDate} from 'helpers';

import Form from 'components/Form';
import Field from 'components/Field';
import QRCodeField from 'components/Field/QRCode';
import Button from 'components/Button';

function TimeField({label, name, register, disabled, required, error}) {
    return (
        <div className="row form-group">
            <div className="col-md-12">
                {label}
            </div>

            <div className="col-4">
                <label htmlFor={name + "_hour"} className="text--small mb-0">
                    Heure
                </label>
                <Field
                    ref={register}
                    name={name + "_hour"}
                    type="number"
                    size="2"
                    min="0"
                    max="24"
                    className={classnames("form-control w-100 input--time", {
                        "error": error
                    })}
                    disabled={disabled}
                    required={required}
                />
            </div>
            <div className="col-4">
                <label htmlFor={name + "_minute"} className="text--small mb-0">
                    Minute
                </label>
                <Field
                    ref={register}
                    name={name + "_minute"}
                    type="number"
                    size="2"
                    min="0"
                    max="60"
                    className={classnames("form-control w-100 input--time", {
                        "error": error
                    })}
                    disabled={disabled}
                    required={required}
                />
            </div>
            <div className="col-4">
                <label htmlFor={name + "_second"} className="text--small mb-0">
                    Seconde
                </label>
                <Field
                    ref={register}
                    name={name + "_second"}
                    type="number"
                    size="2"
                    min="0"
                    max="60"
                    className={classnames("form-control w-100 input--time", {
                        "error": error
                    })}
                    disabled={disabled}
                    required={required}
                />
            </div>

            {error &&
                <div className="col-md-12 text--small mt-1">
                    {error.message}
                </div>
            }
        </div>
    );
}

function mapStateToProps(state) {
    return {
        token: state.auth.data.token,
        nanos: state.nanos.data
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addNotification: (...args) => dispatch(addNotification(...args)),
        create: (...args) => dispatch(mileage.create(...args)),
        getNanos: (...args) => dispatch(getNanos(...args))
    };
}

function MileageForm({token, create, nanos, getNanos, addNotification, onSuccess}) {
    const [fetching, setFetching] = useState(false);
    const {register, handleSubmit, errors, setError, setValue} = useForm({
        defaultValues: {
            start_at_hour: new Date().getHours(),
            start_at_minute: new Date().getMinutes(),
            start_at_second: new Date().getSeconds()
        }
    });

    useEffect(() => {
        getNanos(token);
    }, []);

    const onSubmit = (values) => {
        const { 
            start_at_hour, 
            start_at_minute, 
            start_at_second,
            ...data
        } = values;

        data.start_at = timeIntegersToDate(start_at_hour, start_at_minute, start_at_second);

        create(token, data, {
            success: (response) => {
                addNotification({
                    type: 'success',
                    message: 'L\'analyse a été lancée.',
                });
                
                if (onSuccess) {
                    onSuccess();
                }
            },
            failed: (error) => {
                addNotification({
                    type: 'danger',
                    message: 'Erreur lors de la création de l\'analyse.',
                });
            }
        });
    };

    return (
        <Form 
            className="p-4" 
            onSubmit={handleSubmit((values) => onSubmit(values))}
            disabled={fetching}
        >
            <h5 className="mb-3">
                Lancer une analyse mileage
            </h5>

            <div className="form-group">
                <label htmlFor="qr_code" className="text--small">QR Code</label>
                <QRCodeField
                    name="qr_code"
                    type="text"
                    disabled={fetching}
                    register={register}
                    errors={errors}
                    setValue={(val) => setValue("qr_code", val)}
                />
            </div>

            <div className="form-group">
                <label htmlFor="nano_id">Machine (Jetson Nano)</label>
                <select
                    ref={register}
                    name="nano_id"
                    className="form-control"
                    disabled={fetching || !nanos}
                    placeholder="Sélectionner une Jetson Nano"
                    required
                >
                    {nanos && nanos.data.map(nano => (
                        <option 
                            key={nano.id} 
                            value={nano.id}
                        >
                            {nano.name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="form-group">
                <TimeField 
                    register={register}
                    label="Début *" 
                    name="start_at" 
                    disabled={fetching}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="remark" className="text--small">Remarque</label>
                <textarea
                    name="remark"
                    className="form-control"
                    disabled={fetching}
                    ref={register}
                >
                </textarea>
            </div>

            <Button type="submit" className="btn btn-primary">
                Lancer l'analyse
            </Button>
        </Form>
    );
};

MileageForm.displayName = 'MileageForm';

MileageForm.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(MileageForm);
