import React from 'react';
import {withRouter} from 'react-router-dom';

import { applyFilters } from 'helpers';

import Screen from 'components/Screen';
import Dropdown from 'components/Dropdown';
import Filter from 'components/Filter';
import List from 'components/List';
import SelectDropdown from 'components/Analyses/SelectDropdown';
 
function ScreenList({
    title, 
    filter,  
    data,
    renderHeader,
    renderRow,
    renderNewAction,
    renderList
}) {
    let items = null;
    let filtersLength = null;

    if (data && data.data) {
        if (filter) {
            items = applyFilters(filter.data, data.data);
            filtersLength = Object.keys(filter.data).length;
        } else {
            items = data.data;
        }
    }

    const list = (
        <List
            data={items}
            pageCount={data && data.page_count}
            renderRow={renderRow}
            renderHeader={renderHeader}
        />
    );

    return (
        <Screen 
            title={title} 
            actions={
                <React.Fragment>
                    <Dropdown 
                        target={<span>Filtrer {filtersLength > 0 && `(${filtersLength})`}</span>}
                        targetClassName="btn pill btn-secondary"
                        wrapperClassName="d-inline-block mr-2"
                        position="right"
                    >
                        <Filter
                            filters={filter.data}
                            types={filter.types}
                            onSubmit={filter.add}
                            onDelete={filter.delete}
                            initialValues={{'type': Object.keys(filter.types)[0]}}
                        />
                    </Dropdown>

                    {renderNewAction ? renderNewAction() : <SelectDropdown />}
                </React.Fragment>
            }
        >
            {renderList 
                ? renderList(list)
                : list
            }
        </Screen>
    );
};

ScreenList.displayName = 'ScreenList';

ScreenList.propTypes = {
};
 
export default withRouter(ScreenList);
