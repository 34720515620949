import React from 'react';
import PropTypes from 'prop-types';

/**
 * Caret Functional Component.
 */
export default function Caret(props) {
    return (
        <svg {...props} width="8" height="5" xmlns="http://www.w3.org/2000/svg">
            <path d="M.475 4.09h6.363c.124 0 .23-.044.32-.134a.437.437 0 00.135-.32.437.437 0 00-.135-.32L3.976.136A.437.437 0 003.656 0a.437.437 0 00-.319.135L.155 3.317a.437.437 0 00-.135.32c0 .122.045.229.135.319.09.09.197.135.32.135z" fill="#000" fillRule="nonzero"/>
        </svg>
    );
};

Caret.displayName = 'Caret';

Caret.propTypes = {
};
