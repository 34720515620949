import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {GRID_TYPES} from 'constants';
import {getNanos, nano, addNotification} from 'actions';
import history from '../../history';
import {usePagination} from '../../hooks';
import {URL, post, put} from '../../api';
import {formatBase64, getURLParam, setURLParam, removeURLParam} from '../../helpers';

import Screen from 'components/Screen';
import List from 'components/List';
import Row, {RowHeader} from 'components/Dashboard/Nano/Row';
import Modal from 'components/Modal';
import NanoDrawCrops from 'components/Nano/DrawCrops';

function mapStateToProps(state) {
    return {
        token: state.auth.data.token,
        nanos: state.nanos
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAll: (...args) => dispatch(getNanos(...args)),
        getNano: (...args) => dispatch(nano.read(...args)),
        addNotification: (...args) => dispatch(addNotification(...args))
    };
}

function NanoView({onClose, gridType, onGridTypeChange, nano, onSubmit, onCropRemove, crops, getNano}) {
    const [src, setSrc] = useState(null);
    let timeout;

    useEffect(() => {
        setSrc(null);

        const fetchPreview = () => {
            timeout = setTimeout(() => {
                getNano(nano.id.toString(), "/preview", {
                    success: ({data}) => setSrc(formatBase64(data.image)),
                    failed: (err) => {
                        fetchPreview()
                    }
                });
            }, 1000);
        };

        if (nano) {
            getNano(nano.id.toString(), "/trig_capture", {
                success: () => fetchPreview(),
                failed: () => fetchPreview(),
            });

            return () => {
                clearTimeout(timeout);
            };
        }
    }, []);

    return (
        <Modal open className="p-4 d-block" onClose={onClose}>
            <h5 className="mb-3">Visualiser / modifier les zones d'analyse</h5>
            <div className="mb-2">
                <select
                    className="form-control"
                    placeholder="Sélectionner une analyse"
                    onChange={(e) => onGridTypeChange(e.target.value)}
                    value={gridType}
                    required
                >
                    {Object.keys(GRID_TYPES).map((type, index) =>
                        <option
                            key={index}
                            value={type}
                        >
                            {GRID_TYPES[type]}
                        </option>
                    )}
                </select>
            </div>
            <NanoDrawCrops
                src={src}
                crops={(crops && gridType)
                    ? crops
                    : null
                }
                onSubmit={(crops) => onSubmit(crops)}
                onRemove={(id) => onCropRemove(id)}
            />
        </Modal>
    )
}

function NanoList({token, nanos, getAll, getNano, addNotification}) {
    const [currentNano, setCurrentNano] = useState(null);
    const [crops, setCrops] = useState(null);
    const [currentGridType, setCurrentGridType] = useState(Object.keys(GRID_TYPES)[0]);

    function getCrops() {
        getNano(token, currentNano.id.toString(), `/crops?is_active=true&analysis_type=${currentGridType}&page_size=100`, {
            success: ({data}) => {
                if (data && data.data) {
                    setCrops(data.data);
                }
            }
        });
    }

    useEffect(() => {
        if (nanos.data && getURLParam("id")) {
            const nano = nanos.data.data.find(nano => nano.id === parseInt(getURLParam("id")));
            if (nano) {
                setCurrentNano(nano);
            }
        }
    }, [nanos]);

    useEffect(() => {
        if (currentNano && currentGridType) {
            getCrops();
        }
    }, [currentGridType, currentNano]);

    usePagination(() => getAll(token, window.location.search));

    function onSubmit(crops) {
        const promises = [
            ...crops.filter((crop) => crop.id).map(({id, ...crop}) =>
                put(
                    `/nanos/${currentNano.id.toString()}/crops/${id}`,
                    token,
                    {is_active: false}
                )
            ),
            ...crops.map(({id, ...crop}) =>
                post(
                    `/nanos/${currentNano.id.toString()}/crops`,
                    token,
                    {...crop, analysis_type: currentGridType}
                )
            )
        ];

        promises.reduce((chain, promise) => chain.then(() => promise), Promise.resolve()).then(() => {
            addNotification({
                type: 'success',
                message: 'Les zones d\'analyse ont été enregistrées'
            });
            getCrops();
        }).catch(err => {
            console.log(err);
            addNotification({
                type: 'danger',
                message: 'Erreur lors de l\'enregistrement'
            });
            getCrops();
        });
    }

    return (
        <Screen title="Nanos">
            <List
                data={nanos.data && nanos.data.data}
                fetching={nanos.fetching}
                pageCount={nanos.data && nanos.data.page_count}
                renderRow={({item}) => 
                    <Row 
                        data={item} 
                        onCapture={(data) => {
                            setCurrentNano(data);
                            setURLParam("id", data.id);
                        }} 
                    />
                }
                renderHeader={RowHeader}
            />
            {currentNano && 
                <NanoView
                    nano={currentNano}
                    onClose={() => {
                        removeURLParam("id");
                        setCurrentNano(null);
                    }}
                    gridType={currentGridType}
                    onGridTypeChange={setCurrentGridType}
                    onCropRemove={(id) => put(
                        `/nanos/${currentNano.id.toString()}/crops/${id}`,
                        token,
                        {is_active: false}
                    )}
                    onSubmit={onSubmit}
                    crops={crops}
                    getNano={(...args) => getNano(token, ...args)}
                />
            }
        </Screen>
    );
};

NanoList.displayName = 'NanoList';

NanoList.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(NanoList);
