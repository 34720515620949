import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {getRange} from 'helpers';

import Field from 'components/Field';

export default function NotesField({
    className,
    notesNumber, 
    name, 
    register, 
    disabled, 
    triggerValidation, 
    errors,
    originalNotes
}) {
    return getRange(notesNumber).map((_, index) =>
        <div
            key={index}
            className={classnames("mb-1 d-flex align-items-center border", {
                [className]: className
            })}
        >
            <Field
                className="border-0 pr-2"
                name={`${name}[${index}]`}
                type="text"
                disabled={disabled}
                ref={register({pattern: /^(0|2\.5|5|7\.5|10)$/})}
                error={errors[name] && errors[name][index]}
                onChange={() => triggerValidation(`${name}[${index}]`)}
            />
            {originalNotes && originalNotes[index] &&
                <div className="pr-2 pl-0 text--small text-muted" style={{whiteSpace: "nowrap"}}>
                    {Number(originalNotes[index]).toFixed(1)}
                </div>
            }
        </div>
    );
}

NotesField.displayName = 'NotesField';

NotesField.propTypes = {
    notesNumber: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    triggerValidation: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired
};
