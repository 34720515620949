import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { post, webToken } from '../../api';
import { formatBase64 } from 'helpers';

import Field from 'components/Field';
import CameraIcon from 'components/Icons/Camera';
import Spinner from 'components/Spinner';

export default function QRCodeField({
    className,
    name, 
    register, 
    disabled, 
    errors,
    setValue
}) {
    const [isLoading, setIsLoading] = useState(false);

    function onChange(e) {
        const file = e.target.files[0];

        setIsLoading(true);

        if (FileReader) {
            const reader = new FileReader();
            reader.onload = () => {
                post("/datamatrix", webToken, {
                    image: formatBase64(reader.result, {removeHeader: true})
                }).then(res => {
                    setIsLoading(false);
                    setValue(res.data.value);
                }).catch(() => setIsLoading(false));
            };
            reader.readAsDataURL(file);
        }
    }

    return (
        <div
            className={classnames("qr_code d-flex align-items-center", {
                [className]: className
            })}
        >
            <Field
                className="mr-3"
                name={name}
                type="text"
                disabled={disabled}
                ref={register({required: true})}
                error={errors[name]}
            />
            <div className="file-input">
                <input
                    type='file'
                    name={`${name}_file`}
                    accept='.jpg, .png, .jpeg'
                    onChange={(e) => onChange(e)}
                />
                {isLoading 
                    ? <Spinner style={{width: 22, height: 22}} />
                    : <CameraIcon />
                }
            </div>
        </div>
    );
}

QRCodeField.displayName = 'QRCodeField';

QRCodeField.propTypes = {
    name: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired
};
