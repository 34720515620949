import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import { 
    getAllMileage,
    addFilter,
    deleteFilter,
} from 'actions';
import history from '../../history';
import {usePagination} from '../../hooks';

import ScreenList from 'components/Screen/List';
import Row, {RowHeader} from 'components/Dashboard/Mileage/Row';
import Button from 'components/Button';
import Modal from 'components/Modal';
import MileageForm from 'components/Analyses/MileageForm';

function mapStateToProps(state) {
    return {
        token: state.auth.data.token,
        mileages: state.mileages,
        filters: state.filters
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAll: (...args) => dispatch(getAllMileage(...args)),
        addFilter: (...args) => dispatch(addFilter(...args)),
        deleteFilter: (...args) => dispatch(deleteFilter(...args)),
    };
}

function MileageList({match, token, mileages, filters, getAll, addFilter, deleteFilter}) {
    const [modalForm, setModalForm] = useState(false);
    usePagination(() => getAll(token, window.location.search));
    
    useEffect(() => {
        const interval = setInterval(() => getAll(token, window.location.search), 3000);

        return () => {
            clearInterval(interval);
        }
    });

    return (
        <React.Fragment>
            <ScreenList
                title="Analyses Mileage"
                filter={{
                    data: filters,
                    types: {
                        qr_code: 'QR Code',
                        start_at: 'Début',
                    },
                    add: (values) => addFilter(values),
                    delete: (filter) => deleteFilter(filter),
                }}
                data={mileages.data}
                renderHeader={(props) => <RowHeader {...props} />}
                renderRow={({item}) =>
                    <Row 
                        data={item}
                        onClick={(item) => history.push(`${match.url}/${item.id}`)}
                        match={match}
                        history={history}
                    />
                }
                renderNewAction={() =>
                    <Button 
                        className="btn pill btn-primary"
                        onClick={() => setModalForm(true)}
                    >
                        Lancer une analyse
                    </Button>
                }
            />
            <Modal
                open={modalForm}
                onClose={() => setModalForm(false)}
                className="mileage-form"
            >
                <MileageForm 
                    onSuccess={() => {
                        getAll(token, window.location.search);
                        setModalForm(false);
                    }} 
                />
            </Modal>
        </React.Fragment>
    );
};

MileageList.displayName = 'MileageList';

MileageList.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(MileageList);
