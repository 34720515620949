import React from 'react';

import Sidebar from 'components/Sidebar';
import Header from 'components/Header';

export default function Screen({children, title, actions}) {
    return (
        <div className="Dashboard">
            <Sidebar />
            <div className="Main">
                <Header title={title} actions={actions} />
                {children}
            </div>
        </div>
    );
};

Screen.displayName = 'Screen';
