import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Spinner from  './../Spinner';

/**
 * Modal Functional Component.
 */
export default function Modal({open, fetching, children, onClose, className}) {
    const style = {
        display: open ? 'block' : 'none',
        visibility: open ? 'visible' : 'hidden'
    };

    return (
        <div className="Modal" style={style}>
            <div className="background" onClick={() => onClose && onClose()}>
                {' '}
            </div>
            <div className={"content " + className}>{fetching ? <Spinner /> : children}</div>
        </div>
    );
};

Modal.displayName = 'Modal';

Modal.propTypes = {
    open: PropTypes.bool.isRequired,
    children: PropTypes.node,
    fetching: PropTypes.bool,
    onClose: PropTypes.func,
    className: PropTypes.string,
};
