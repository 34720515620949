import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import urlJoin from 'url-join';

import {deskem, getDeskEMPicture} from 'actions';
import {getTime, getRange} from 'helpers';
import {URL} from '../../api';

import ScreenView from 'components/Screen/View';
import Spinner from 'components/Spinner';

function mapStateToProps(state) {
    return {
        token: state.auth.data.token,
        analysis: state.deskem.data,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        reset: () => dispatch(deskem.reset()),
        get: (...args) => dispatch(deskem.read(...args)),
        destroy: (...args) => dispatch(deskem.delete(...args)),
        getPicture: (...args) => dispatch(getDeskEMPicture(...args)),
        getAll: (...args) => dispatch(getAllDeskThickness(...args)),
    };
}

function DeskEMView({match, token, analysis, reset, get, getPicture, destroy}) {
    const [picture, setPicture] = useState(null);

    useEffect(() => {
        reset();
        get(token, match.params.id);
        getPicture(token, match.params.id, 'picture?base64=true&grid=true', {
            success: (response) => setPicture(`data:image/jpg;base64,${response.data.image}`),
            failed: () => setPicture(false)
        });
    }, []);

    return (
        <ScreenView
            title={`Analyse ${match.params.id}`}
            onDelete={() => destroy(token, match.params.id, {
                success: () => getAll(token)
            })}
            actions={
                <a
                    className="btn pill btn-secondary mr-4"
                    href={`${URL}desk_ems/${match.params.id}/xml?token=${token}`}
                    download={true}
                >
                    Export XML
                </a>
            }
            editLink
        >
            {analysis
                ? (
                    <div>
                        <div className="col-md-6 p-0 info">
                            <div className="p-3">
                                <div className="mb-4">
                                    <h4 className="font-weight-bold">Analyse Desk EM</h4>
                                </div>

                                <div className="mb-4">
                                    <dl className="row">
                                        <dt className="col-sm-3 mb-3">QR Code</dt>
                                        <dd className="col-sm-9 mb-3 font-weight-bold">{analysis.qr_code}</dd>

                                        <dt className="col-sm-3 mb-3 font-weight-normal">Remarque</dt>
                                        <dd className="col-sm-9 mb-3">{analysis.remark}</dd>

                                        <dt className="col-sm-3 font-weight-normal">Smoothness</dt>
                                        <dd className="col-sm-9">{analysis.smoothness}</dd>
                                        <dt className="col-sm-3 font-weight-normal">Irreg major defect</dt>
                                        <dd className="col-sm-9">{analysis.irreg_major_defect}</dd>
                                        <dt className="col-sm-3 font-weight-normal">Irreg minor defect</dt>
                                        <dd className="col-sm-9">{analysis.irreg_minor_defect}</dd>
                                        <dt className="col-sm-3 font-weight-normal">Dirty major defect</dt>
                                        <dd className="col-sm-9">{analysis.dirty_major_defect}</dd>
                                        <dt className="col-sm-3 mb-3 font-weight-normal">Dirty minor defect</dt>
                                        <dd className="col-sm-9 mb-3">{analysis.dirty_minor_defect}</dd>

                                        <dt className="col-sm-3 font-weight-normal">Date de création</dt>
                                        <dd className="col-sm-9">{getTime(analysis.created_at)}</dd>

                                        <dt className="col-sm-3 font-weight-normal">Utilisateur</dt>
                                        <dd className="col-sm-9">
                                            {analysis.created_by_user && `${analysis.created_by_user.username} (${analysis.created_by_user.role})`}
                                        </dd>
                                        <dt className="col-sm-3 font-weight-normal">Jetson Nano</dt>
                                        {analysis.created_by_nano && 
                                            <dd className="col-sm-9">
                                                {analysis.created_by_nano.name} (#{analysis.created_by_nano.id})
                                            </dd>
                                        }
                                    </dl>
                                </div>

                                <div className="mb-4 d-flex">
                                    <div className="flex--1"><b>Crayon</b></div>
                                    <div className="flex--1"><b>Sale</b></div>
                                    <div className="flex--1"><b>Irreg</b></div>
                                </div>

                                {getRange(5).map((item, index) => (
                                    <div className="mb-4 d-flex" key={`line-${index}`}>
                                        <div className="flex--1">
                                            {analysis.pen_numbers && analysis.pen_numbers[index] !== "None" && analysis.pen_numbers[index]}
                                        </div>
                                        <div className="d-flex flex--1">
                                            {analysis.analysis_sale && analysis.analysis_sale[index]}

                                            {analysis.original_analysis_sale && analysis.original_analysis_sale[index] &&
                                                <div className="ml-3 text--small text-muted">
                                                    ({Number(analysis.original_analysis_sale[index]).toFixed(1)})
                                                </div>
                                            }
                                        </div>
                                        <div className="d-flex flex--1">
                                            {analysis.analysis_irreg && analysis.analysis_irreg[index]}

                                            {analysis.original_analysis_irreg && analysis.original_analysis_irreg[index] &&
                                                <div className="ml-3 text--small text-muted">
                                                    ({Number(analysis.original_analysis_irreg[index]).toFixed(1)})
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-md-6 p-0 capture position-relative">
                            {picture && <img src={picture} className="img-fluid" />}
                            {picture === false && <div className='text-center'>Erreur lors de la récupération de l'image</div>}
                            {picture === null && <Spinner />}
                        </div>
                    </div>
                )
                : <Spinner />
            }
        </ScreenView> 
    );
};

DeskEMView.displayName = 'DeskEMView';

DeskEMView.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskEMView);
