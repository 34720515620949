import React from 'react';
import PropTypes from 'prop-types';

/**
 * Signing Functional Component.
 */
export default function Signing({className}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width="24" height="24" viewBox="0 0 512 512">
            <path d="M329.606 482H48.992c-8.284 0-15 6.716-15 15s6.716 15 15 15h280.614c8.284 0 15-6.716 15-15s-6.716-15-15-15zM464.399 29.164c-10.874-10.875-25.332-16.863-40.71-16.863s-29.836 5.989-40.71 16.863l-2.438 2.438L353.33 4.393c-5.857-5.857-15.355-5.858-21.213 0l-85.294 85.294c-5.858 5.858-5.858 15.355 0 21.213 5.858 5.858 15.356 5.858 21.213 0l74.688-74.687 16.603 16.603-288.42 288.42-.005.005-.005.005-23.506 23.506a14.994 14.994 0 0 0-4.184 8.109L30.975 445.3a14.998 14.998 0 0 0 17.288 17.289l72.44-12.232a15.001 15.001 0 0 0 8.109-4.184l335.587-335.587c22.448-22.449 22.448-58.974 0-81.422zM110.985 421.572l-46.916 7.923 7.922-46.917 9.518-9.518 38.994 38.994-9.518 9.518zm30.731-30.731l-38.994-38.994 123.285-123.285 38.994 38.994-123.285 123.285zm144.499-144.498l-38.994-38.994 133.32-133.32 38.994 38.994-133.32 133.32zM443.187 89.372l-2.438 2.438-38.994-38.994 2.437-2.439c5.208-5.208 12.132-8.076 19.497-8.076s14.29 2.869 19.498 8.076c10.75 10.751 10.75 28.244 0 38.995z"/>
        </svg>
    );
};

Signing.displayName = 'Signing';

Signing.propTypes = {
};
