import * as types from './types';
import * as api from '../api';
import { STORAGE_AUTH_KEY } from 'constants';

function refreshToken() {
    return new Promise((resolve, reject) => {
        // Get auth object.
        let auth = localStorage.getItem(STORAGE_AUTH_KEY);
        if (auth === null) {
            reject("Could not get auth object from localStorage.");
        }
        auth = JSON.parse(auth);

        api.post(
            'oauth/token', 
            '', 
            {
                client_id: api.CLIENT_ID,
                grant_type: 'refresh_token',
                refresh_token: auth.refresh_token
            }
        )
        .then(response => resolve(response.data))
        .catch(error => {
            let msg = "";
            if (error.response && error.response.data && error.response.data.error) {
                msg = error.response.data.error;
            }
            reject(msg);
        });
    });
}

function makeAction(type, ...argNames) {
    return (...args) => {
        const action = { type };

        argNames.forEach((arg, index) => {
            action[argNames[index]] = args[index]
        });

        return action;
    };
}

/**
 * Creates an API action.
 * @param  {String} type                Root type of the action.
 * @param  {String} method              HTTP Method (post, get, ...)
 * @param  {String} endpoint            API endpoint (after base url, see api.js)
 * @param  {Function} callbackSuccess   Optional callback on successfull call
 * @param  {Function} callbackFailed    Optional callback on failed call
 * @return {Function}                   Generated action
 */
function makeApiAction(type, method='get', endpoint='') {
    const FETCHING = type + '_FETCHING';
    const SUCCESS = type + '_SUCCESS';
    const FAILED = type + '_FAILED';

    return function action(...args) {
        // Are there callbacks ?
        let callbacks = {success: null, failed: null};
        const lastArg = args[args.length - 1];
        if (
            lastArg && 
            (lastArg.success || lastArg.failed)
        ) {
            callbacks = args.pop();
        }

        return (dispatch) => {
            dispatch({type: FETCHING});

            api[method](endpoint, ...args).then((response) => {
                let payload = response.data;
                if (method === 'delete') {
                    payload = [];
                }

                dispatch({type: SUCCESS, payload});

                if (callbacks.success) {
                    callbacks.success(response);
                }
            }).catch((error) => {
                let msg = ''
                if (error.response && error.response.data) {
                    msg = error.response.data.error;
                }

                if (msg === 'Invalid token' || error.response.status === 401) {
                    refreshToken().then(data => {
                        let newArgs = [...args];
                        newArgs[0] = data.token;

                        dispatch({type: types.LOGIN + '_SUCCESS', payload: data});
                        action(...newArgs)(dispatch);
                        return;
                    }).catch(message => {
                        console.log(message);
                        dispatch({type: types.LOGOUT});
                        window.location.replace('/login');
                        //TODO: display toaster
                        return;
                    });

                    return;
                }
                if (error.response.status !== 404 && error.response.status !== 401 && msg) {
                    dispatch({
                        type: "NOTIFICATION_ADDED",
                        payload: {
                            type: "danger",
                            message: "Erreur : " + msg,
                        }
                    })
                }

                dispatch({type: FAILED, payload: error.response && error.response.data});
                if (callbacks.failed) {
                    callbacks.failed(error);
                }
            });
        };
    }
}

function makeCrudAction(type, endpoint='') {    
    return {
        reset: makeAction(type + '_RESET'),
        create: makeApiAction(type + '_CREATE', 'post', endpoint),
        read: makeApiAction(type + '_READ', 'get', endpoint),
        update: makeApiAction(type + '_UPDATE', 'put', endpoint),
        delete: makeApiAction(type + '_DELETE', 'destroy', endpoint),
    };
}

export const addNotification = makeAction(types.NOTIFICATION_ADDED, 'payload');
export const deleteNotification = makeAction(types.NOTIFICATION_DELETED, 'payload');

export const addFilter = makeAction(types.FILTER_ADDED, 'payload');
export const deleteFilter = makeAction(types.FILTER_DELETED, 'payload');
export const resetFilters = makeAction(types.FILTER_RESET);

export const login = makeApiAction(types.LOGIN, 'post', 'authentication/login');
export const logout = makeAction(types.LOGOUT);

export const getAllDeskMW = makeApiAction(types.DESKMW + '_ALL', 'get', 'desk_mws');
export const deskmw = makeCrudAction(types.DESKMW, 'desk_mws');
export const getDeskMWPicture = makeApiAction(types.DESKMW + '_PICTURE', 'get', 'desk_mws');
export const getDeskMWPrediction = makeApiAction('', 'post', 'desk_mws/analyze');

export const getAllDeskEM = makeApiAction(types.DESKEM + '_ALL', 'get', 'desk_ems');
export const deskem = makeCrudAction(types.DESKEM, 'desk_ems');
export const getDeskEMPicture = makeApiAction(types.DESKEM + '_PICTURE', 'get', 'desk_ems');
export const getDeskEMPrediction = makeApiAction('', 'post', 'desk_ems/analyze');

export const getAllDeskThickness = makeApiAction(types.DESKTHICKNESS + '_ALL', 'get', 'desk_thicknesses');
export const deskthickness = makeCrudAction(types.DESKTHICKNESS, 'desk_thicknesses');
export const getDeskThicknessPicture = makeApiAction(types.DESKTHICKNESS + '_PICTURE', 'get', 'desk_thicknesses');

export const cleardrain = makeCrudAction(types.CLEARDRAIN, 'cleardrains');

export const seepage = makeCrudAction(types.SEEPAGE, 'seepages');

export const getAllMileage = makeApiAction(types.MILEAGE + '_ALL', 'get', 'machine_mileages');
export const mileage = makeCrudAction(types.MILEAGE, 'machine_mileages');
export const changeMileageState = makeApiAction('', 'post', 'machine_mileages');
export const updateMileage = makeApiAction('', 'put', 'machine_mileages');
export const getMileagePictures = makeApiAction('', 'get', 'machine_mileages');
export const getMileagePicture = makeApiAction('', 'get', 'machine_mileage_pictures');
export const updateMileagePicture = makeApiAction('', 'put', 'machine_mileage_pictures');

export const getAllDeskDrainback = makeApiAction(types.DESKDRAINBACK + '_ALL', 'get', 'desk_drain_backs');
export const deskdrainback = makeCrudAction(types.DESKDRAINBACK, 'desk_drain_backs');
export const getDeskDrainbackPicture = makeApiAction(types.DESKDRAINBACK + '_PICTURE', 'get', 'desk_drain_backs');

export const getRaspberries = makeApiAction(types.RASPBERRIES, 'get', 'raspberries');
export const raspberry = makeCrudAction(types.RASPBERRY, 'raspberries');
export const getRaspberryView = makeApiAction(types.RASPBERRY + '_CAPTURE', 'get', 'raspberries');
export const resetRaspberryView = makeAction(types.RASPBERRY + '_RESET');

export const getNanos = makeApiAction(types.NANOS, 'get', 'nanos');
export const nano = makeCrudAction(types.NANO, 'nanos');
export const getNanoView = makeApiAction(types.NANO + '_CAPTURE', 'get', 'nanos');
export const resetNanoView = makeAction(types.NANO + '_RESET');

export const contact = makeApiAction(types.CONTACT, 'post', 'contact');
