import React from 'react';
import PropTypes from 'prop-types';

import { getTime } from 'helpers';

export function RowHeader() {
    return (
        <div className="Header">
            <div className="flex--1">
                ID
            </div>
            <div className="flex--1">
                Distance
            </div>
            <div className="flex--2">
                Date de création
            </div>
        </div>
    );
}

/**
 * Row Functional Component.
 */
export default function Row({data, onClick, selected}) {
    let className = "Row";
    if (onClick) {
        className += " clickable";
    }
    if (selected) {
        className += " selected";
    }

    return (
        <div className={className} onClick={() => onClick(data)}>
            <div className="flex--1">
                {data.id}
            </div>
            <div className="flex--1">
                {data.distance}m
            </div>
            <div className="flex--2">
                {getTime(data.created_at)}
            </div>
        </div>
    );
};

Row.displayName = 'Row';

Row.propTypes = {
};
