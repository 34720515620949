import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import urlJoin from 'url-join';

import {addNotification, deskmw, getDeskMWPicture, resetFilters} from 'actions';
import {getRange} from 'helpers';
import history from '../../history';
import {useDeskForm} from '../../hooks';

import Form from 'components/Form';
import Field from 'components/Field';
import NotesField from 'components/Field/Notes';
import QRCodeField from 'components/Field/QRCode';
import Spinner from 'components/Spinner';
import Screen from 'components/Screen';
import DeskFormConfirmModal from 'components/Analyses/DeskFormConfirmModal';
import Button from 'components/Button';

function mapStateToProps(state) {
    return {
        token: state.auth.data.token,
        resource: state.deskmw
    };
}

function mapDispatchToProps(dispatch) {
    return {
        get: (...args) => dispatch(deskmw.read(...args)),
        getPicture: (...args) => dispatch(getDeskMWPicture(...args)),
        create: (...args) => dispatch(deskmw.create(...args)),
        update: (...args) => dispatch(deskmw.update(...args)),
        reset: () => dispatch(deskmw.reset()),
        resetFilters: () => dispatch(resetFilters()),
        addNotification: (...args) => dispatch(addNotification(...args))  
    };
}

function DeskMWForm({match, create, ...formProps}) {
    const {
        register,
        handleSubmit,
        triggerValidation,
        setValue,
        errors,
        fetching,
        onSubmit,
        picture,
        nano
    } = useDeskForm({
        ...formProps,
        id: match.params.id,
        onValuesSet: (setValue, data) => {
            const fields = [
                "remark"
            ];
            fields.forEach(field => data[field] && setValue(field, data[field]));
        },
        onSaveSuccess: () => history.push('/analyses/desk_mws')
    });

    return (
        <Screen
            title={match.params.id
                ? `Modifier l'analyse Desk MW ${match.params.id}`
                : "Créer une analyse Desk MW"
            }
        >
            <div className="col-md-10 mx-auto p-4">
                <div className="capture position-relative mb-2">
                    {picture 
                        ? <img src={picture} className="img-fluid" />
                        : <Spinner />
                    }
                </div>

                <Form 
                    onSubmit={handleSubmit(onSubmit)}
                    disabled={fetching}
                >
                    <div className="d-flex align-items-center mb-2">
                        <div className="flex--1">Trace</div>
                        {getRange(10).map((_, index) =>
                            <div key={index} className="flex--1 text-center form-control border-0 px-0">
                                {index + 1}
                            </div>
                        )}
                    </div>

                    <div className="d-flex align-items-center mb-1">
                        <div className="flex--1">Sale</div>
                        <NotesField
                            className="mx-1 flex--1"
                            notesNumber={10}
                            name="analysis_sale"
                            register={register}
                            triggerValidation={triggerValidation}
                            errors={errors}
                            disabled={fetching}
                            originalNotes={formProps.resource.data && formProps.resource.data.original_analysis_sale}
                        />
                    </div>

                    <div className="d-flex align-items-center mb-2">
                        <div className="flex--1">Irreg</div>
                        <NotesField
                            className="mx-1 flex--1"
                            notesNumber={10}
                            name="analysis_irreg"
                            register={register}
                            triggerValidation={triggerValidation}
                            errors={errors}
                            disabled={fetching}
                            originalNotes={formProps.resource.data && formProps.resource.data.original_analysis_irreg}
                        />
                    </div>

                    <div className="text--small mb-4">
                        Valeurs possibles : 0 ; 2.5 ; 5 ; 7.5 ; 10
                    </div>

                    <div className="row mb-4">
                        <div className="col-6">
                            <div className="mb-2">QR code</div>
                            <QRCodeField
                                name="qr_code"
                                type="text"
                                disabled={fetching}
                                register={register}
                                errors={errors}
                                setValue={(val) => setValue("qr_code", val)}
                            />
                        </div>
                        <div className="col-6">
                            <div className="mb-2">Remarque</div>
                            <textarea
                                name="remark"
                                ref={register}
                                className="form-control"
                            >
                            </textarea>
                        </div>
                    </div>

                    <div>
                        <button 
                            type="submit" 
                            className="btn btn-primary"
                            disabled={fetching}
                        >
                            Envoyer
                        </button>
                    </div>
                </Form>

                {!match.params.id && 
                    <DeskFormConfirmModal 
                        onConfirm={() => create(formProps.token, {nano_id: nano}, {
                            success: ({data}) => history.replace(urlJoin(
                                '/analyses/desk_mws', 
                                data.id.toString(), 
                                'edit'
                            )),
                            failed: () => addNotification({
                                type: 'danger',
                                message: 'Erreur lors du lancement de l\'analyse'
                            })
                        })}
                    />
                }
            </div>
        </Screen>
    );
};

DeskMWForm.displayName = 'DeskMWForm';

DeskMWForm.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskMWForm);
