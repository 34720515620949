import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import Logo from 'components/Icons/Logo';
import Info from 'components/Icons/Info';
import Message from 'components/Icons/Message';
import Router from 'components/Icons/Router';
import Create from 'components/Icons/Create';
import Circles from 'components/Icons/Circles';
import Person from 'components/Icons/Person';
import Exit from 'components/Icons/Exit';
import Drop from 'components/Icons/Drop';
import Signing from 'components/Icons/Signing';
import Pen from 'components/Icons/Pen';

function mapStateToProps(state) {
    return {
        auth: state.auth.data,
    };
}

function Sidebar({auth}) {
    const routes = [
        {path: "/analyses/desk_mws", title: "Desk MW", icon: Create},
        {path: "/analyses/desk_ems", title: "Desk EM", icon: Create},
        {path: "/analyses/desk_drainbacks", title: "Desk Drainback", icon: Create},
        {path: "/analyses/desk_thicknesses", title: "Line Width", icon: Signing},
        {path: "/analyses/mileages", title: "Mileage", icon: Pen},
        // {path: "/analyses/cleardrains", title: "Cleardrain", icon: Circles},
        // {path: "/analyses/seepages", title: "Seepage", icon: Drop},
    ];

    return (
        <React.Fragment>
            <ul className="Sidebar nav flex-column">
                <div className="nav-header">
                    <Link className="nav-brand" to="/" style={{ height:"50px" }}>
                        <Logo />
                    </Link>
                </div>

                <li className="py-2 nav-content">
                    <div className="nav-link">Analyses :</div>

                    {routes.map(({path, title, icon: Icon}, index) =>
                        <NavLink 
                            key={index}
                            className="nav-link"
                            activeClassName="active"
                            to={path}
                        >
                            <Icon className="mr-2" /> {title}
                        </NavLink>
                    )}
                        
                    <div className="nav-link mt-1">Gestion :</div>

                    <NavLink 
                        className="nav-link" 
                        activeClassName="active" 
                        to="/nanos"
                    >
                        <Router className="mr-2" /> Nanos
                    </NavLink>
                </li>

                <div className="nav-footer">
                    <div className="nav-link pb-0">
                        <Person className="mr-2" /> {auth.user && auth.user.username}
                    </div>
                    <Link className="nav-link" to="/logout">
                        <Exit className="mr-2" /> Déconnexion
                    </Link>
                </div>
            </ul>
        </React.Fragment>
    );
};

Sidebar.displayName = 'Sidebar';

Sidebar.propTypes = {
};

export default connect(mapStateToProps)(Sidebar);
