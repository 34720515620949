import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const AuthRoute = ({ render, component: Component, location, auth, group, ...rest }) => {
    if (auth.data === null) {
        return <Route {...rest} render={() => <Redirect to={
            `/login?redirect=${location.pathname.substr(1) || '/'}`
        } />} />;
    }

    if (auth.data && group && (auth.data.group > group)) {
        return <Route {...rest} render={() => <Redirect to="/" />} />;
    }

    if (render) {
        return <Route {...rest} render={render} />;
    }

    return <Route {...rest} render={(props) => <Component { ...props} />} />;
};

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default withRouter(connect(mapStateToProps)(AuthRoute));
