import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DatePicker from "react-datepicker";
import fr from 'date-fns/locale/fr';

/**
 * RenderDatePicker Functional Component.
 */
export default function RenderDatePicker({
    className, 
    popperPlacement, 
    input: {value, onChange}, 
    meta: {touched, error, warning},
}) {
    return (
        <React.Fragment>
            <DatePicker
                className={classnames({[className]: className})}
                selected={value ? new Date(value) : null}
                dateFormat="yyyy-MM-dd"
                onChange={onChange}
                locale={fr}
                popperPlacement={popperPlacement}
            />
            {touched && error && typeof error === "string" && (
                <span className="d-inline-block text--small">{error}</span>
            )}
        </React.Fragment>
    );
};

RenderDatePicker.displayName = 'RenderDatePicker';

RenderDatePicker.propTypes = {
};
