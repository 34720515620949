export const STORAGE_AUTH_KEY = 'p-bic-user';


export const GRID_TYPES = {
    'desk_mw': 'Desk MW',
    'desk_em': 'Desk EM',
    'desk_drain_back': 'Desk Drainback',
    'machine_mileage': 'Mileage',
}

export const DESK_EM_SMOOTHNESS_TYPES = {
    scratchy: 'scratchy',
    smooth: 'smooth',
    very_smooth: 'very_smooth',
    dry: 'dry',
    very_scratchy: 'very_scratchy',
    strained: 'strained',
    very_strained: 'very_strained',
    ultrasmooth: 'ultrasmooth',
    irregular_smoothness: 'irregular_smoothness',
};
export const DESK_EM_DIRTY_TYPES = {
    blob: 'blob',
    dot: 'dot',
    goop: 'goop',
    string: 'string',
};
export const DESK_EM_IRREG_TYPES = {
    pale_writing: 'pale_writing',
    skip: 'skip',
    split: 'split',
    starv: 'starv',
};

export const VALID_MIMETYPES = ["image/png", "image/jpg", "image/jpeg", "application/pdf"];


export const DESKMW_FORM = 'deskMWForm';

export const DESKEM_FORM = 'deskEMForm';

export const DESKTHICKNESS_FORM = 'deskThicknessForm';

export const DESKDRAINBACK_FORM = 'deskdrainbackForm';

export const FILTER_FORM = 'filterForm';

export const CONTACT_FORM = 'contactForm';

export const CLEARDRAIN_FORM = 'cleardrainForm';

export const SEEPAGE_FORM = 'seepageForm';

export const MILEAGE_FORM = 'mileageForm';
