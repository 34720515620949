import React from 'react';
import PropTypes from 'prop-types';

/**
 * CaretDouble Functional Component.
 */
export default function CaretDouble(props) {
    return (
        <svg {...props} width="8" height="10" xmlns="http://www.w3.org/2000/svg">
            <path d="M.475 4.09h6.363c.124 0 .23-.044.32-.134a.437.437 0 0 0 .135-.32.437.437 0 0 0-.135-.32L3.976.136A.437.437 0 0 0 3.656 0a.437.437 0 0 0-.319.135L.155 3.317a.437.437 0 0 0-.135.32c0 .122.045.229.135.319.09.09.197.135.32.135zM6.838 5.91H.475a.437.437 0 0 0-.32.134.437.437 0 0 0-.135.32c0 .123.045.23.135.32l3.182 3.18c.09.09.197.136.32.136s.23-.045.32-.135l3.181-3.182a.437.437 0 0 0 .135-.32.437.437 0 0 0-.135-.319.437.437 0 0 0-.32-.135z"/>
        </svg>
    );
};

CaretDouble.displayName = 'CaretDouble';

CaretDouble.propTypes = {
};
