import React from 'react';
import PropTypes from 'prop-types';

/**
 * Circles Functional Component.
 */
export default function Circles({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M9 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm8-13.74v2.09c2.33.82 4 3.04 4 5.65s-1.67 4.83-4 5.65v2.09c3.45-.89 6-4.01 6-7.74 0-3.73-2.55-6.85-6-7.74z"/>
        </svg>
    );
};

Circles.displayName = 'Circles';

Circles.propTypes = {
};
