import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import { 
    getAllDeskDrainback, 
    addFilter,
    deleteFilter,
} from 'actions/index.js';
import history from '../../history';
import {usePagination} from '../../hooks';

import ScreenList from 'components/Screen/List';
import Row, {RowHeader} from 'components/Dashboard/DeskDrainback/Row';

function mapStateToProps(state) {
    return {
        token: state.auth.data.token,
        deskdrainbacks: state.deskdrainbacks,
        filters: state.filters,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAll: (...args) => dispatch(getAllDeskDrainback(...args)),
        addFilter: (...args) => dispatch(addFilter(...args)),
        deleteFilter: (...args) => dispatch(deleteFilter(...args)),
    };
}

function DeskDrainbackList({match, token, deskdrainbacks, filters, getAll, addFilter, deleteFilter}) {
    usePagination(() => getAll(token, window.location.search));
    
    return (
        <ScreenList
            title="Analyses Desk Drainback"
            filter={{
                data: filters,
                types: {
                    qr_code: 'QR Code',
                    client_group: 'Créateur',
                    raspberry_name: 'Raspberry',
                    created_at: 'Date'
                },
                add: (values) => addFilter(values),
                delete: (filter) => deleteFilter(filter),
            }}
            data={deskdrainbacks.data}
            renderHeader={(props) => <RowHeader {...props} />}
            renderRow={({item}) =>
                <Row 
                    data={item}
                    onClick={(item) => history.push(`${match.url}/${item.id}`)}
                    match={match}
                    history={history}
                />
            }
        />
    );
};

DeskDrainbackList.displayName = 'DeskDrainbackList';

DeskDrainbackList.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskDrainbackList);
