import React from 'react';
import { CSSTransition } from 'react-transition-group';

class NotificationMessage extends React.Component {
    constructor() {
        super();

        this.state = {
            in: false
        };
    }
    
    componentDidMount() {
        this.setState({in: true});
        
        if (!this.props.notification.stay) {  
            this[`timeout${this.props.uniqueKey}`] = 
                setTimeout(() => (this.setState({in: false})), 4000); 
        }
    }

    componentWillUnmount() {
        this.props.deleteNotification(this.props.index);
    }

    render() {
        const { notification } = this.props;

        return (
            <CSSTransition
                in={this.state.in}
                timeout={500}
                classNames="notification_message_animation"
                unmountOnExit
            >
                <div className={`notification_message box-shadow alert alert-${notification.type}`}>
                    {notification.message}
                    <button
                        type="button" 
                        className="close ml-3" 
                        onClick={() => this.setState({in: false})}>
                        &times;
                    </button>
                </div>
            </CSSTransition>
        );
    }
}

export default NotificationMessage;
