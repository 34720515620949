import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';

import history from '../../history';

import Screen from 'components/Screen';
import ModalDelete from 'components/Modal/Delete';

function ScreenView({
    match,
    title,
    actions,
    onDelete,
    editLink = true,
    children
}) {
    const [deleteModal, setDeleteModal] = useState(false);

    return (
        <Screen
            title={title}
            actions={
                <React.Fragment>
                    {actions && actions}

                    {onDelete && (
                        <button 
                            onClick={() => setDeleteModal(true)}
                            className="btn pill btn-danger"
                        >
                            Supprimer
                        </button>
                    )}
                    {editLink && (
                        <Link to={`${match.url}/edit`} className="btn pill btn-primary ml-4">
                            Modifier
                        </Link>
                    )}
                </React.Fragment>
            }
        >
            {children}

            {onDelete && (
                <ModalDelete
                    open={deleteModal}
                    onClose={() => setDeleteModal(false)}
                    message="Voulez vous vraiment supprimer cette analyse ?"
                    onDelete={() => {
                        onDelete();
                        history.goBack();
                    }}
                />
            )}
        </Screen>
    );
};

ScreenView.displayName = 'ScreenView';

ScreenView.propTypes = {
};

export default withRouter(ScreenView);
