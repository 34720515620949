import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {deskthickness, getAllDeskThickness, getDeskThicknessPicture} from 'actions';
import {getTime, getRange} from 'helpers';
import {post, URL} from '../../api';

import ScreenView from 'components/Screen/View';
import Spinner from 'components/Spinner';

function mapStateToProps(state) {
    return {
        token: state.auth.data.token,
        analysis: state.deskthickness.data,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        reset: () => dispatch(deskthickness.reset()),
        get: (...args) => dispatch(deskthickness.read(...args)),
        destroy: (...args) => dispatch(deskthickness.delete(...args)),
        getPicture: (...args) => dispatch(getDeskThicknessPicture(...args)),
        getAll: (...args) => dispatch(getAllDeskThickness(...args)),
        addNotification: (...args) => dispatch(addNotification(...args))
    };
}

function DeskThicknessView({match, token, analysis, reset, get, getPicture, destroy, addNotification}) {
    const [picture, setPicture] = useState(null);

    useEffect(() => {
        reset();
        get(token, match.params.id);
        getPicture(token, match.params.id, 'picture?base64=true&grid=true', {
            success: (response) => setPicture(`data:image/jpg;base64,${response.data.image}`),
            failed: () => setPicture(false)
        });
    }, []);

    function xmlTransfer() {
        post(`/desk_thicknesses/${match.params.id}/transfer_xml`, token).then(() =>
            addNotification({
                type: 'success',
                message: 'Transfert effectué'
            })
        ).catch(() =>
            addNotification({
                type: 'danger',
                message: 'Erreur lors du transfert'
            })
        );
    }

    return (
        <ScreenView
            title={`Analyse ${match.params.id}`}
            onDelete={() => destroy(token, match.params.id, {
                success: () => getAll(token)
            })}
            actions={
                <React.Fragment>
                    <a
                        className="btn pill btn-secondary mr-4"
                        href={`${URL}desk_thicknesses/${match.params.id}/picture?processed=true&token=${token}`}
                        download={true}
                    >
                        Squelette
                    </a>
                    <button
                        className="btn pill btn-secondary mr-4"
                        onClick={() => xmlTransfer()}
                    >
                        Transfert XML
                    </button>
                    <a
                        className="btn pill btn-secondary mr-4"
                        href={`${URL}desk_thicknesses/${match.params.id}/csv?token=${token}`}
                        download={true}
                    >
                        Export CSV
                    </a>
                </React.Fragment>
            }
            editLink={false}
        >
            {analysis
                ? (
                    <div className="row">
                        <div className="col-md-6 info">
                            <div className="p-3">
                                <div className="mb-4">
                                    <h4 className="font-weight-bold">Analyse Line Width</h4>
                                </div>

                                <div className="mb-4">
                                    <dl className="row">
                                        <dt className="col-sm-4 font-weight-normal">Nom du fichier</dt>
                                        <dd className="col-sm-8">{analysis.name}</dd>

                                        <dt className="col-sm-4 font-weight-normal">Remarque</dt>
                                        <dd className="col-sm-8">{analysis.remark}</dd>

                                        <dt className="col-sm-4 font-weight-normal">Date de création</dt>
                                        <dd className="col-sm-8">{getTime(analysis.created_at)}</dd>

                                        <dt className="col-sm-4 font-weight-normal">Utilisateur</dt>
                                        <dd className="col-sm-8">{analysis.created_by_user && analysis.created_by_user.username}</dd>

                                        <dt className="col-sm-4 font-weight-normal">Moyenne</dt>
                                        <dd className="col-sm-8">{analysis.mean}</dd>

                                        <dt className="col-sm-4 font-weight-normal">Écart-type</dt>
                                        <dd className="col-sm-8">{analysis.std}</dd>
                                    </dl>
                                </div>

                                <div className="mb-4 d-flex">
                                    <div className="flex--1"><b>Trace</b></div>
                                    <div className="flex--1"><b>Épaisseur</b></div>
                                </div>

                                {analysis.measures && getRange(10).map((item, index) => (
                                    <div className="mb-4 d-flex" key={`line-${index}`}>
                                        <div className="flex--1">
                                            {index + 1}
                                        </div>
                                        <div className="flex--1">
                                            {analysis.measures[index]}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-md-6 p-0 capture position-relative">
                            {picture && <img src={picture} className="img-fluid" />}
                            {picture === false && <div className='text-center'>Erreur lors de la récupération de l'image</div>}
                            {picture === null && <Spinner />}
                        </div>
                    </div>
                )
                : <Spinner />
            }
        </ScreenView> 
    );
};

DeskThicknessView.displayName = 'DeskThicknessView';

DeskThicknessView.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskThicknessView);
