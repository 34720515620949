import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useForm} from "react-hook-form";

import {addNotification, deskthickness} from 'actions';
import history from '../../history';

import Screen from 'components/Screen';
import Form from 'components/Form';
import Field from 'components/Field';
import DropzoneField from 'components/Field/Dropzone';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import ExitCircle from 'components/Icons/ExitCircle';

function mapStateToProps(state) {
    return {
        token: state.auth.data.token
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addNotification: (...args) => dispatch(addNotification(...args)),
        create: (...args) => dispatch(deskthickness.create(...args))
    };
}

function DeskThicknessForm({addNotification, create, token}) {
    const [fetching, setFetching] = useState(false);
    const [filesFetching, setFilesFetching] = useState([]);
    const [files, setFiles] = useState([]);
    const {register, handleSubmit, errors} = useForm();

    const onSubmit = (values) => {
        setFetching(true);

        let _filesFetching = files.map(() => true);

        setFilesFetching(_filesFetching);

        const promises = files.map((image, index) => 
            new Promise((resolve, reject) => {
                const data = {
                    ...values,
                    name: image.file.name.split(".")[0],
                    type: image.file.type,
                    // "data:[mimetype];base64,"
                    image: image.base64.substring(13 + image.file.type.length)
                };

                create(token, data, {
                    success: () => resolve({index: index}),
                    failed: () => reject({index: index, name: data.name})
                });
            })
        );

        promises.forEach(promise => promise.then(res => {
            _filesFetching[res.index] = false;
            setFilesFetching(_filesFetching);

            if (res.index === files.length - 1) {
                setFetching(false);
                addNotification({
                    type: 'success',
                    message: 'Analyses enregistrées',
                });
                history.push("/analyses/desk_thicknesses");
            }
        }).catch(err => {
            _filesFetching[err.index] = false;
            setFilesFetching(_filesFetching);
            addNotification({
                type: 'danger',
                message: `Erreur lors de l'analyse de l'image : ${err.name}`,
            });

            if (res.index === files.length - 1) {
                setTimeout(() => {
                    setFetching(false);
                    history.push("/analyses/desk_thicknesses");
                }, 1000);
            }
        }));
    };

    const removeFile = (index) => {
        const _files = [...files];
        _files.splice(index, 1);
        setFiles(_files);
    };

    return (
        <Screen title="Créer une analyse Line Width">
            <Form 
                className="p-3 w-100 d-flex justify-content-center align-items-center"
                onSubmit={handleSubmit(onSubmit)}
                disabled={fetching}
            >
                <div className="mb-2">
                    <div className="form-group">
                        <label htmlFor="remark" className="text--small">Remarque</label>
                        <textarea
                            name="remark"
                            className="form-control"
                            ref={register}
                        >
                        </textarea>
                    </div>
                </div>

                <div className="mb-3">
                    <DropzoneField 
                        value={files}
                        onChange={(files) => setFiles(files)}
                        onError={(error) => addNotification({
                            type: 'danger',
                            message: error
                        })}
                    />
                </div>
                <div className="selected-files">
                    {files && files.map((image, index) => (
                        <div 
                            className="file" 
                            key={image.file.name} 
                            style={{
                                backgroundImage: `url(${image.base64})`,
                                opacity: filesFetching[index] ? '0.5' : '1'
                            }}
                        >
                            {filesFetching[index] && <Spinner style={{opacity: '1'}} />}
                            <ExitCircle className="exit-icon" onClick={() => removeFile(index)} />
                        </div>
                    ))}
                </div>
                {files && files.length !== 0 && (
                    <div className="w-100 text-center">
                        <Button type="submit" className="btn btn-primary">
                            Envoyer
                        </Button>
                    </div>
                )}
            </Form>
        </Screen>
    );
};

DeskThicknessForm.displayName = 'DeskThicknessForm';

DeskThicknessForm.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskThicknessForm);
