import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { STORAGE_AUTH_KEY } from 'constants';
import * as types from 'actions/types.js';

import { notificationReducer } from './notification.js';
import { filterReducer } from './filter.js';

const apiInitalState = {
    fetching: false,
    error: null,
    data: null,
}

function apiHandlers(type) {
    return {
        [type + '_FETCHING']: (state, action) => {
            return {...state, fetching: true, error: null};
        },
        [type + '_SUCCESS']: (state, action) => {
            return {...state, fetching: false, error: null, data: action.payload};
        },
        [type + '_FAILED']: (state, action) => {
            return {...state, fetching: false, error: action.payload};
        }
    }
}

function createReducer(initialState, handlers) {
    return (state = initialState, action) => {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action)
        } else {
            return state;
        }
    };
}

function createApiReducer(type, initialState = apiInitalState, handlers = null) {
    return createReducer(initialState, {
        ...apiHandlers(type),
        ...handlers
    });
}

function createCrudReducer(type, initialState = apiInitalState, handlers = null) {
    const types = {
        create: type + '_CREATE',
        read: type + '_READ',
        update: type + '_UPDATE',
        delete: type + '_DELETE',
    };

    return createReducer(initialState, {
        [type + '_RESET']: (state, action) => {
            return {...apiInitalState}
        },
        ...apiHandlers(types.create),
        ...apiHandlers(types.read),
        ...apiHandlers(types.update),
        ...apiHandlers(types.delete),
        ...handlers
    });
}

const rootReducer = combineReducers({
    notifications: notificationReducer,
    auth: createApiReducer(
        types.LOGIN, 
        {...apiInitalState, data: JSON.parse(localStorage.getItem(STORAGE_AUTH_KEY))},
        {
            [types.LOGIN + '_SUCCESS']: (state, action) => {
                localStorage.setItem(STORAGE_AUTH_KEY, JSON.stringify(action.payload));
                return {...state, data: action.payload, fetching: null, error: null};
            },
            [types.LOGOUT]: (state, action) => {
                localStorage.removeItem(STORAGE_AUTH_KEY);
                return {...state, data: null};
            }
        }
    ),
    deskmws: createApiReducer(types.DESKMW + '_ALL'),
    deskmw: createCrudReducer(types.DESKMW),
    deskems: createApiReducer(types.DESKEM + '_ALL'),
    deskem: createCrudReducer(types.DESKEM),
    deskthicknesses: createApiReducer(types.DESKTHICKNESS + '_ALL'),
    deskthickness: createCrudReducer(types.DESKTHICKNESS),
    deskdrainbacks: createApiReducer(types.DESKDRAINBACK + '_ALL'),
    deskdrainback: createCrudReducer(types.DESKDRAINBACK),
    mileages: createApiReducer(types.MILEAGE + '_ALL'),
    mileage: createCrudReducer(types.MILEAGE),
    raspberries: createApiReducer(
        types.RASPBERRIES,
        apiInitalState
    ),
    raspberry: createCrudReducer(types.RASPBERRY),
    raspberryView: createApiReducer(types.RASPBERRY + '_CAPTURE', apiInitalState, {
        [types.RASPBERRY + '_RESET']: (state, action) => {
            return {...state, data: null};
        }
    }),
    nanos: createApiReducer(
        types.NANOS,
        apiInitalState
    ),
    nano: createCrudReducer(types.NANO),
    nanoView: createApiReducer(types.NANO + '_CAPTURE', apiInitalState, {
        [types.NANO + '_RESET']: (state, action) => {
            return {...state, data: null};
        }
    }),
    filters: filterReducer,
    formWarnings: createReducer({}, {
        'FORM_WARNING_ADD': (state, action) => {
            return {
                ...state, 
                [action.form]: {
                    ...state[action.form], 
                    ...action.payload,
                }
            };
        },
        'FORM_WARNING_REMOVE': (state, action) => {
            const copy = {...state};
            if (copy[action.form]) {
                delete copy[action.form][action.payload];
            }
            return copy;
        }
    }),
    form: formReducer
});

export default rootReducer;
