import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';

/**
 * ModalDelete Functional Component.
 */
export default function ModalDelete({open, onClose, message, onDelete}) {
    return (
        <Modal open={open} onClose={onClose}>
            <div className="d-flex align-items-center text-center p-4 w-100 h-100">
                <div className="w-100">
                    <div className="mb-4">
                        {message}
                    </div>
                    <button 
                        type="button"
                        className="btn btn-secondary mr-3"
                        onClick={onClose}
                    >
                        Annuler
                    </button>
                    <button 
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {onClose(); onDelete();}}
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </Modal>
    );
};

ModalDelete.displayName = 'ModalDelete';

ModalDelete.propTypes = {
    onDelete: PropTypes.func.isRequired,
};
