import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import { 
    getAllDeskThickness, 
    addFilter,
    deleteFilter,
} from 'actions/index.js';
import history from '../../history';
import { usePagination } from '../../hooks';

import ScreenList from 'components/Screen/List';
import Row, { RowHeader } from 'components/Dashboard/DeskThickness/Row';

function mapStateToProps(state) {
    return {
        token: state.auth.data.token,
        deskthicknesses: state.deskthicknesses,
        filters: state.filters,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAll: (...args) => dispatch(getAllDeskThickness(...args)),
        addFilter: (...args) => dispatch(addFilter(...args)),
        deleteFilter: (...args) => dispatch(deleteFilter(...args)),
    };
}

function DeskThicknessList({match, token, deskthicknesses, filters, getAll, addFilter, deleteFilter}) {
    usePagination(() => getAll(token, window.location.search));

    return (
        <ScreenList
            title="Analyses Line Width"
            filter={{
                data: filters,
                types: {
                    name: 'Nom',
                    client_group: 'Créateur',
                    created_at: 'Date'
                },
                add: (values) => addFilter(values),
                delete: (filter) => deleteFilter(filter),
            }}
            data={deskthicknesses.data}
            renderHeader={(props) => <RowHeader {...props} />}
            renderRow={({item}) =>
                <Row 
                    data={item}
                    onClick={(item) => history.push(`${match.url}/${item.id}`)}
                    match={match}
                    history={history}
                />
            }
            renderNewAction={() => (
                <Link className="btn pill btn-primary" to={`${match.url}/new`}>
                    Nouvelle analyse
                </Link>
            )}
        />
    );
};

DeskThicknessList.displayName = 'DeskThicknessList';

DeskThicknessList.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskThicknessList);
