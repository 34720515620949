// import 'url-search-params-polyfill';

// import { FILTER_TYPES } from 'constants';
import { FILTER_RESET, FILTER_ADDED, FILTER_DELETED } from 'actions/types.js';

function initState() {
    // const search = new URLSearchParams(window.location.search);
    let state = {};

    // for (var item of search) {
    //     // Make sure url param are valid filter params
    //     if (Object.keys(FILTER_TYPES).indexOf(item[0]) !== -1) {
    //         state[item[0]] = item[1];
    //     }
    // }

    return state;
}

const initialState = {...initState()};

export const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case FILTER_RESET:
            return {...initState()};
        case FILTER_ADDED:
            return {...state, [action.payload.type]: action.payload.value};
        case FILTER_DELETED:
            let filters = {...state};
            delete filters[action.payload];
            return filters
        default:
            return state;
    }
};
