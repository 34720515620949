import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

export function RowHeader() {
    return (
        <div className="Header">
            <div className="flex--1">
                ID
            </div>
            <div className="flex--2">
                Nom
            </div>
            <div className="flex--2">
                IP Locale
            </div>
            <div className="flex--3 text-right">
                Actions
            </div>
        </div>
    );
}

/**
 * Row Functional Component.
 */
export default function Row({data, onCapture}) {
    let className = "Row";

    return (
        <div className={className}>
            <div className="flex--1">
                {data.id}
            </div>
            <div className="flex--2">
                {data.name}
            </div>
            <div className="flex--2">
                {data.local_ip_address}
            </div>
            <div className="flex--3 text-right">
                <Button
                    className="btn btn-primary mr-2"
                    onClick={(e) => onCapture(data)}
                >
                    Capture
                </Button>
                <a
                    href={`http://${data.local_ip_address}:8000/api/stream`}
                    target="_blank"
                    className="btn btn-primary"
                >
                    Live
                </a>
            </div>
        </div>
    );
};

Row.displayName = 'Row';

Row.propTypes = {
    data: PropTypes.object.isRequired
};
