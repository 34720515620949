import {useState, useEffect} from 'react';
import {useForm} from "react-hook-form";

import {getURLParam, setURLParam, removeURLParam} from 'helpers';
import history from './history';

export function usePagination(getData) {
    const page = getURLParam('page');
    const pageSize = getURLParam('page_size');

    useEffect(() => {
        if (!page) {
            setURLParam('page', '1');
        }
        if (!pageSize) {
            setURLParam('page_size', '25');
        }
    }, []);

    useEffect(() => {
        getData();
    }, [window.location.search]);

    return {
        page,
        pageSize,
        setPage: (page) => setURLParam('page', page),
        setPageSize: (pageSize) => setURLParam('page_size', pageSize)
    };
}

export function useDeskForm({
    id, 
    token,
    resource, 
    reset, 
    addNotification, 
    get, 
    getPicture,
    update,
    onSaveSuccess,
    notesFields=["analysis_sale", "analysis_irreg"],
    defaultValues,
    onValuesSet
}) {
    const [fetching, setFetching] = useState(false);
    const [picture, setPicture] = useState(null);
    const formHook = useForm({defaultValues});

    const nano = getURLParam('nano_id');

    useEffect(() => {
        const qrCode = getURLParam('qr_code');

        if (qrCode && qrCode !== '') {
            formHook.setValue('qr_code', qrCode);
            removeURLParam('qr_code');
        }

        if (!id) {
            formHook.reset();
            reset();

            if (!nano) {
                addNotification({
                    type: 'danger',
                    message: 'Enregistrement impossible : pas de nano spécifiée',
                });
                history.goBack();
            }
        } else {
            setFetching(resource.fetching);

            if (!resource.data) {
                formHook.reset();
                get(token, id);
            }

            let timeout;

            const refetchData = () => {
                timeout = setTimeout(() => {
                    get(token, id);
                    getPicture(token, id, 'picture?base64=true&grid=true', {
                        success: ({data}) => {
                            setPicture(`data:image/jpg;base64,${data.image}`);
                        },
                        failed: (err) => {
                            refetchData()
                        }
                    });
                }, 1000);
            };
            refetchData();

            return () => {
                clearTimeout(timeout);
            };
        }
    }, []);

    useEffect(() => {
        if (resource.data) {
            formHook.setValue([
                ...notesFields.map(field => ({[field]: resource.data[field]})),
                {"qr_code": resource.data.qr_code}
            ]);

            if (onValuesSet) {
                onValuesSet(formHook.setValue, resource.data);
            }
        }
    }, [resource]);

    const onSubmit = (values) => {        
        notesFields.forEach(field => 
            values[field] = values[field].map(note => note ? parseFloat(note) : null)
        );

        if (values.pen_numbers) {
            values.pen_numbers = values.pen_numbers.map(num => num ? parseInt(num) : null);
        }

        setFetching(true);

        update(token, values, id, {
            success: ({data}) => {
                setFetching(false);
                onSaveSuccess(data);
            },
            failed: () => {
                setFetching(false);
                addNotification({
                    type: 'danger',
                    message: 'Erreur lors du lancement de l\'analyse'
                });
            }
        });
    };

    return {
        ...formHook,
        fetching,
        setFetching,
        picture,
        setPicture,
        onSubmit,
        nano
    };
}
