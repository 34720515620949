import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import urlJoin from 'url-join';

import {addNotification, deskem, getDeskEMPicture, resetFilters} from 'actions';
import {getRange} from 'helpers';
import history from '../../history';
import {useDeskForm} from '../../hooks';
import {DESK_EM_SMOOTHNESS_TYPES, DESK_EM_DIRTY_TYPES, DESK_EM_IRREG_TYPES} from 'constants';

import Form from 'components/Form';
import Field from 'components/Field';
import NotesField from 'components/Field/Notes';
import QRCodeField from 'components/Field/QRCode';
import Spinner from 'components/Spinner';
import Screen from 'components/Screen';
import DeskFormConfirmModal from 'components/Analyses/DeskFormConfirmModal';
import Button from 'components/Button';

function mapStateToProps(state) {
    return {
        token: state.auth.data.token,
        resource: state.deskem
    };
}

function mapDispatchToProps(dispatch) {
    return {
        get: (...args) => dispatch(deskem.read(...args)),
        getPicture: (...args) => dispatch(getDeskEMPicture(...args)),
        create: (...args) => dispatch(deskem.create(...args)),
        update: (...args) => dispatch(deskem.update(...args)),
        reset: () => dispatch(deskem.reset()),
        resetFilters: () => dispatch(resetFilters()),
        addNotification: (...args) => dispatch(addNotification(...args))  
    };
}

function DeskEMForm({match, create, ...formProps}) {
    const {
        register,
        handleSubmit,
        triggerValidation,
        errors,
        fetching,
        onSubmit,
        picture,
        nano,
        setValue
    } = useDeskForm({
        ...formProps,
        id: match.params.id,
        defaultValues: {
            pen_numbers: getRange(5).map((_, index) => index + 1)
        },
        onValuesSet: (setValue, data) => {
            const fields = [
                "remark",
                "smoothness", 
                "irreg_major_defect", 
                "irreg_minor_defect",
                "dirty_major_defect",
                "dirty_minor_defect"
            ];
            fields.forEach(field => data[field] && setValue(field, data[field]));
        },
        onSaveSuccess: () => history.push('/analyses/desk_ems')
    });

    return (
        <Screen
            title={match.params.id
                ? `Modifier l'analyse Desk EM ${match.params.id}`
                : "Créer une analyse Desk EM"
            }
        >
            <div className="row m-0">
                <Form 
                    className="col-md-6 p-3"
                    onSubmit={handleSubmit(onSubmit)}
                    disabled={fetching}
                >
                    <div className="row mb-2">
                        <div className="col-3">
                            <div className="mb-2">Crayon</div>
                            {getRange(5).map((_, index) =>
                                <Field
                                    key={index}
                                    className="mb-1"
                                    name={`pen_numbers[${index}]`}
                                    type="number"
                                    min="1"
                                    max="5"
                                    disabled={fetching}
                                    ref={register}
                                />
                            )}
                        </div>

                        <div className="col-3">
                            <div className="mb-2">Sale</div>

                            <NotesField
                                notesNumber={5}
                                name="analysis_sale"
                                register={register}
                                triggerValidation={triggerValidation}
                                errors={errors}
                                disabled={fetching}
                                originalNotes={formProps.resource.data && formProps.resource.data.original_analysis_sale}
                            />
                        </div>

                        <div className="col-3">
                            <div className="mb-2">Irreg</div>

                            <NotesField
                                notesNumber={5}
                                name="analysis_irreg"
                                register={register}
                                triggerValidation={triggerValidation}
                                errors={errors}
                                disabled={fetching}
                                originalNotes={formProps.resource.data && formProps.resource.data.original_analysis_irreg}
                            />
                        </div>
                    </div>
                    <div className="text--small mb-4">
                        Valeurs possibles : 0 ; 2.5 ; 5 ; 7.5 ; 10
                    </div>

                    <div className="row mb-3">
                        <div className="col-6">
                            <div className="mb-2">QR code</div>
                            <QRCodeField
                                name="qr_code"
                                type="text"
                                disabled={fetching}
                                register={register}
                                errors={errors}
                                setValue={(val) => setValue("qr_code", val)}
                            />
                        </div>
                        <div className="col-6">
                            <div className="mb-2">Remarque</div>
                            <textarea
                                name="remark"
                                ref={register}
                                className="form-control"
                            >
                            </textarea>
                        </div>
                    </div>

                    <div className="mb-2">
                        <div className="mb-2">Smoothness</div>
                        <select
                            name="smoothness"
                            className="form-control"
                            disabled={fetching}
                            ref={register}
                        >
                            {Object.keys(DESK_EM_SMOOTHNESS_TYPES).map((type, index) =>
                                <option
                                    key={index}
                                    value={type}
                                >
                                    {DESK_EM_SMOOTHNESS_TYPES[type]}
                                </option>
                            )}
                        </select>
                    </div>
                    <div className="row mb-2">
                        <div className="col-6">
                            <div className="mb-2">Irreg major defect</div>
                            <select
                                name="irreg_major_defect"
                                className="form-control"
                                disabled={fetching}
                                ref={register}
                            >
                                {Object.keys(DESK_EM_IRREG_TYPES).map((type, index) =>
                                    <option
                                        key={index}
                                        value={type}
                                    >
                                        {DESK_EM_IRREG_TYPES[type]}
                                    </option>
                                )}
                            </select>
                        </div>

                        <div className="col-6">
                            <div className="mb-2">Irreg minor defect</div>
                            <select
                                name="irreg_minor_defect"
                                className="form-control"
                                disabled={fetching}
                                ref={register}
                            >
                                {Object.keys(DESK_EM_IRREG_TYPES).map((type, index) =>
                                    <option
                                        key={index}
                                        value={type}
                                    >
                                        {DESK_EM_IRREG_TYPES[type]}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-6">
                            <div className="mb-2">Dirty major defect</div>
                            <select
                                name="dirty_major_defect"
                                className="form-control"
                                disabled={fetching}
                                ref={register}
                            >
                                {Object.keys(DESK_EM_DIRTY_TYPES).map((type, index) =>
                                    <option
                                        key={index}
                                        value={type}
                                    >
                                        {DESK_EM_DIRTY_TYPES[type]}
                                    </option>
                                )}
                            </select>
                        </div>

                        <div className="col-6">
                            <div className="mb-2">Dirty minor defect</div>
                            <select
                                name="dirty_minor_defect"
                                className="form-control"
                                disabled={fetching}
                                ref={register}
                            >
                                {Object.keys(DESK_EM_DIRTY_TYPES).map((type, index) =>
                                    <option
                                        key={index}
                                        value={type}
                                    >
                                        {DESK_EM_DIRTY_TYPES[type]}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>

                    <div>
                        <button 
                            type="submit" 
                            className="btn btn-primary"
                            disabled={fetching}
                        >
                            Envoyer
                        </button>
                    </div>
                </Form>

                <div className="col-md-6 p-0">
                    {picture 
                        ? <img src={picture} className="img-fluid" />
                        : <Spinner />
                    }
                </div>

                {!match.params.id && 
                    <DeskFormConfirmModal 
                        onConfirm={() => create(formProps.token, {nano_id: nano}, {
                            success: ({data}) => history.replace(urlJoin(
                                '/analyses/desk_ems', 
                                data.id.toString(), 
                                'edit'
                            )),
                            failed: () => addNotification({
                                type: 'danger',
                                message: 'Erreur lors du lancement de l\'analyse'
                            })
                        })}
                    />
                }
            </div>
        </Screen>
    );
};

DeskEMForm.displayName = 'DeskEMForm';

DeskEMForm.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskEMForm);
