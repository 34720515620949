import React from 'react';
import PropTypes from 'prop-types';

/**
 * Create Functional Component.
 */
export default function Create({className}) {
    return (
        <svg className={className} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="m3 17.25v3.75h3.75l11.06-11.06-3.75-3.75zm2.92 1.75h-.92v-.92l9.06-9.06.92.92zm14.79-13.37-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z"/>
        </svg>
    );
};

Create.displayName = 'Create';

Create.propTypes = {
};
