import React from 'react';
import PropTypes from 'prop-types';

import { getTime } from 'helpers';

import SortableColumn from 'components/Dashboard/SortableColumn';
import Status from './Status';

export function RowHeader({}) {
    return (
        <div className="Header">
            <SortableColumn className="flex--1 item" name="qr_code">
                QR Code
            </SortableColumn>
            <div className="flex--1">
                Status
            </div>
            <div className="flex--1">
                Distance
            </div>
            <SortableColumn className="flex--1 item" name="start_at">
                Début
            </SortableColumn>
            <div className="flex--1">
                Créateur
            </div>
            <div className="flex--1">
                Jetson Nano
            </div>
        </div>
    );
}

/**
 * Row Functional Component.
 */
export default function Row({data, onClick}) {
    let className = "Row";
    if (onClick) {
        className += " clickable";
    }

    return (
        <div className={className} onClick={() => onClick(data)}>
            <div className="flex--1">
                {data.qr_code}
            </div>
            <div className="flex--1">
                <Status startAt={data.start_at} running={data.is_currently_running} />
            </div>
            <div className="flex--1">
                {data.distance}m
            </div>
            <div className="flex--1">
                {getTime(data.start_at)}
            </div>
            <div className="flex--1">
                {data.created_by_user && data.created_by_user.username}
            </div>
            <div className="flex--1">
                {data.created_by_nano && 
                    <span>
                        {data.created_by_nano && data.created_by_nano.name}
                    </span>
                }
            </div>
        </div>
    );
};

Row.displayName = 'Row';

Row.propTypes = {
};
