import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { MILEAGE_FORM } from 'constants';

import Form from 'components/Form';
import TimeFields from 'components/Form/TimeFields';

function MileageFormResume({handleSubmit, onSubmit}) {
    return (
        <Form 
            onSubmit={handleSubmit((values) => onSubmit(values))} 
            className="p-2"
        >
            <TimeFields
                label="Nouvelle heure de fin"
                name="end_at"
            />

            <div className="text-center">
                <button 
                    type="submit" 
                    className="btn btn-secondary"
                >
                    Continuer
                </button>
            </div>
        </Form>
    );
};

MileageFormResume.displayName = 'MileageFormResume';

MileageFormResume.propTypes = {
};

export default reduxForm({
    form: MILEAGE_FORM + "_RESUME"
})(MileageFormResume);
