import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Spinner Functional Component.
 */
export default function Spinner({message, style, className, noCenter}) {
    return (
        <div className={classnames("spinner", {
            [className]: className,
            "spinner--center": !noCenter
        })} style={style}>
            <svg className="icon" viewBox="0 0 50 50">
                <circle 
                    className="path" 
                    cx="25" 
                    cy="25" 
                    r="20" 
                    fill="none" 
                    strokeWidth="5"
                >
                </circle>
            </svg>
            {message && (
                <div className="mt-3">
                    {message}
                </div>
            )}
        </div>
    );
};

Spinner.displayName = 'Spinner';

Spinner.propTypes = {
    message: PropTypes.string
};
