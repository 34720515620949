import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import RenderField from 'components/Form/RenderField';

/**
 * TimeFields Functional Component.
 */
export default function TimeFields({label, name, disabled}) {
    return (
        <div className="row form-group">
            <div className="col-md-12">
                {label}
            </div>

            <div className="col-4">
                <label htmlFor={name + "_hour"} className="text--small mb-0">
                    Heure
                </label>
                <Field
                    className="w-100 input--time" 
                    name={name + "_hour"}
                    component={RenderField} 
                    type="number"
                    size="2"
                    min="0"
                    max="24"
                    disabled={disabled}
                    noLabel
                />
            </div>
            <div className="col-4">
                <label htmlFor={name + "_minute"} className="text--small mb-0">
                    Minute
                </label>
                <Field
                    className="w-100 input--time" 
                    name={name + "_minute"}
                    component={RenderField} 
                    type="number"
                    size="2"
                    min="0"
                    max="60"
                    disabled={disabled}
                    noLabel
                />
            </div>
            <div className="col-4">
                <label htmlFor={name + "_second"} className="text--small mb-0">
                    Seconde
                </label>
                <Field
                    className="w-100 input--time" 
                    name={name + "_second"}
                    component={RenderField} 
                    type="number"
                    size="2"
                    min="0"
                    max="60"
                    disabled={disabled}
                    noLabel
                />
            </div>
        </div>
    );
};

TimeFields.displayName = 'TimeFields';

TimeFields.propTypes = {
};
