import React from 'react';
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logout } from 'actions/index.js';

class Logout extends React.Component {
    componentDidMount() {
        this.props.logout();
        window.location.reload();
    }

    render() {
        return (
            <Redirect to="/" />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        logout,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(Logout);
