import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import urlJoin from 'url-join';

import {addNotification, deskdrainback, getDeskDrainbackPicture, resetFilters} from 'actions';
import {getRange} from 'helpers';
import history from '../../history';
import {useDeskForm} from '../../hooks';

import Form from 'components/Form';
import Field from 'components/Field';
import NotesField from 'components/Field/Notes';
import Spinner from 'components/Spinner';
import Screen from 'components/Screen';
import DeskFormConfirmModal from 'components/Analyses/DeskFormConfirmModal';
import QRCodeField from 'components/Field/QRCode';
import Button from 'components/Button';

function mapStateToProps(state) {
    return {
        token: state.auth.data.token,
        resource: state.deskdrainback
    };
}

function mapDispatchToProps(dispatch) {
    return {
        get: (...args) => dispatch(deskdrainback.read(...args)),
        getPicture: (...args) => dispatch(getDeskDrainbackPicture(...args)),
        create: (...args) => dispatch(deskdrainback.create(...args)),
        update: (...args) => dispatch(deskdrainback.update(...args)),
        reset: () => dispatch(deskdrainback.reset()),
        resetFilters: () => dispatch(resetFilters()),
        addNotification: (...args) => dispatch(addNotification(...args))  
    };
}

function DeskDrainbackForm({match, create, ...formProps}) {
    const {
        register,
        handleSubmit,
        triggerValidation,
        errors,
        fetching,
        onSubmit,
        picture,
        nano,
        setValue
    } = useDeskForm({
        ...formProps,
        id: match.params.id,
        notesFields: ["grades_top", "grades_bottom"],
        onSaveSuccess: () => history.push('/analyses/desk_drainbacks'),
        onValuesSet: (setValue, data) => {
            data.grades_top.forEach((grade, index) => {
                if (grade === -1) {
                    setValue(`clogging_top[${index}]`, true);
                    setValue(`grades_top[${index}]`, null);
                }
            });
            data.grades_bottom.forEach((grade, index) => {
                if (grade === -1) {
                    setValue(`clogging_bottom[${index}]`, true);
                    setValue(`grades_bottom[${index}]`, null);
                }
            });
        }
    });

    const _onSubmit = (values) => {
        if (values.clogging_top) {
            values.clogging_top.forEach((val, index) => {
                if (val === true) {
                    values.grades_top[index] = -1;
                }
            });
        }
        if (values.clogging_bottom) {
            values.clogging_bottom.forEach((val, index) => {
                if (val === true) {
                    values.grades_bottom[index] = -1;
                }
            });
        }

        onSubmit(values);
    };

    return (
        <Screen
            title={match.params.id
                ? `Modifier l'analyse Desk Drainback ${match.params.id}`
                : "Créer une analyse Desk Drainback"
            }
        >
            <div className="col-md-10 mx-auto p-4">
                <div className="capture position-relative mb-2">
                    {picture 
                        ? <img src={picture} className="img-fluid" />
                        : <Spinner />
                    }
                </div>

                <Form 
                    onSubmit={handleSubmit(_onSubmit)}
                    disabled={fetching}
                >
                    <div className="d-flex align-items-center mb-2">
                        <div className="flex--1">Trace</div>
                        {getRange(8).map((_, index) =>
                            <div key={index} className="flex--1 text-center form-control border-0 px-0">
                                {index + 1}
                            </div>
                        )}
                    </div>

                    <div className="d-flex align-items-center mb-1">
                        <div className="flex--1">Haut</div>
                        {getRange(8).map((_, index) =>
                            <Field
                                key={index}
                                className="flex--1 mx-1 px-0 text-center"
                                name={`grades_top[${index}]`}
                                type="number"
                                min="0"
                                max="10"
                                disabled={fetching}
                                ref={register}
                            />
                        )}
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <div className="flex--1 text--small">Clogging haut</div>
                        {getRange(8).map((_, index) =>
                            <div key={index} className="flex--1">
                                <Field
                                    className="form-control--checkbox mx-auto"
                                    name={`clogging_top[${index}]`}
                                    type="checkbox"
                                    disabled={fetching}
                                    ref={register}
                                />
                            </div>
                        )}
                    </div>

                    <div className="d-flex align-items-center mb-1">
                        <div className="flex--1">Bas</div>
                        {getRange(8).map((_, index) =>
                            <Field
                                key={index}
                                className="flex--1 mx-1 px-0 text-center"
                                name={`grades_bottom[${index}]`}
                                type="number"
                                min="0"
                                max="10"
                                disabled={fetching}
                                ref={register}
                            />
                        )}
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <div className="flex--1 text--small">Clogging bas</div>
                        {getRange(8).map((_, index) =>
                            <div key={index} className="flex--1">
                                <Field
                                    className="form-control--checkbox mx-auto"
                                    name={`clogging_bottom[${index}]`}
                                    type="checkbox"
                                    disabled={fetching}
                                    ref={register}
                                />
                            </div>
                        )}
                    </div>

                    <div className="text--small mb-4">
                        Valeurs possibles : 0 ; 2.5 ; 5 ; 7.5 ; 10
                    </div>

                    <div className="row mb-4">
                        <div className="col-6">
                            <div className="mb-2">QR code</div>
                            <QRCodeField
                                name="qr_code"
                                type="text"
                                disabled={fetching}
                                register={register}
                                errors={errors}
                                setValue={(val) => setValue("qr_code", val)}
                            />
                        </div>
                    </div>

                    <div>
                        <button 
                            type="submit" 
                            className="btn btn-primary"
                            disabled={fetching}
                        >
                            Envoyer
                        </button>
                    </div>
                </Form>
            </div>

            {!match.params.id && 
                <DeskFormConfirmModal 
                    onConfirm={() => create(formProps.token, {nano_id: nano}, {
                        success: ({data}) => history.replace(urlJoin(
                            '/analyses/desk_drainbacks', 
                            data.id.toString(), 
                            'edit'
                        )),
                        failed: () => addNotification({
                            type: 'danger',
                            message: 'Erreur lors du lancement de l\'analyse'
                        })
                    })}
                />
            }
        </Screen>
    );
};

DeskDrainbackForm.displayName = 'DeskDrainbackForm';

DeskDrainbackForm.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskDrainbackForm);
