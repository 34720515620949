import React from 'react';
import PropTypes from 'prop-types';

/**
 * PlannedCircle Functional Component.
 */
export default function PlannedCircle(props) {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 514 514" xmlns="http://www.w3.org/2000/svg"><path d="M257 513C115.615 513 1 398.385 1 257S115.615 1 257 1s256 114.615 256 256-114.615 256-256 256zm0-58c109.352 0 198-88.648 198-198S366.352 59 257 59 59 147.648 59 257s88.648 198 198 198z" stroke="#FFF" fill="#757575" fillRule="evenodd"/></svg>
    );
};

PlannedCircle.displayName = 'PlannedCircle';

PlannedCircle.propTypes = {
};
