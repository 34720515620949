import React from 'react';
import PropTypes from 'prop-types';

/**
 * Drop Functional Component.
 */
export default function Drop({className}) {
    return (
        <svg className={className} width="24" height="24" viewBox="-57 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path d="m322.125 315.171875c-8.28125 0-15 6.714844-15 15 0 27.867187-9.324219 50.027344-27.710938 65.867187-6.277343 5.40625-6.980468 14.878907-1.574218 21.15625 2.964844 3.445313 7.15625 5.210938 11.371094 5.210938 3.464843 0 6.949218-1.195312 9.78125-3.636719 25.304687-21.796875 38.132812-51.605469 38.132812-88.597656 0-8.285156-6.714844-15-15-15zm0 0"/><path d="m302.640625 124.71875c-45.824219-65.605469-92.308594-119.046875-92.773437-119.578125-2.847657-3.265625-6.972657-5.140625-11.304688-5.140625s-8.453125 1.875-11.304688 5.140625c-.460937.53125-46.949218 53.972656-92.773437 119.578125-62.695313 89.757812-94.484375 158.882812-94.484375 205.453125 0 54.46875 20.394531 100.824219 58.976562 134.058594 35.761719 30.804687 85.335938 47.769531 139.585938 47.769531 54.253906 0 103.828125-16.964844 139.589844-47.773438 38.582031-33.234374 58.972656-79.589843 58.972656-134.054687 0-46.570313-31.789062-115.695313-94.484375-205.453125zm15.929687 316.78125c-30.316406 26.117188-72.9375 40.5-120.007812 40.5s-89.6875-14.382812-120.007812-40.5c-31.765626-27.363281-48.554688-65.859375-48.554688-111.328125 0-39.703125 30.75-104.730469 88.929688-188.058594 31.46875-45.074219 63.265624-84.296875 79.632812-103.917969 16.335938 19.578126 48.039062 58.679688 79.484375 103.703126 58.277344 83.429687 89.078125 148.535156 89.078125 188.273437 0 45.46875-16.789062 83.964844-48.554688 111.328125zm0 0"/>
        </svg>
    );
};

Drop.displayName = 'Drop';

Drop.propTypes = {
};
