import React from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { range, setURLParam, getURLParam } from 'helpers';

import Spinner from 'components/Spinner';

export default function List({
    data, 
    fetching,
    pageCount,
    renderRow,
    renderHeader,
    className
}) {
    let page = getURLParam("page", history);
    if (!page && pageCount) {
        page = 1;
    }
    page = parseInt(page, 10);
    const pageEllipsisInterval = 2;

    const changePage = (page) => {
        setURLParam("page", page, "push");
    };

    return (
        <div className={`List ${className && className}`}>
            {renderHeader && renderHeader()}
            {data && !fetching ? (
                <React.Fragment>
                    {data.map((item, index) => (
                        <React.Fragment key={item.id || index}> 
                            {renderRow({item})}
                        </React.Fragment>
                    ))}
                    {page && pageCount && pageCount > 1 ? (
                        <div className="paginate px-2 py-3">
                            {page > 1 && (
                                <span className="page-back" onClick={() => changePage(page - 1)}>
                                    Précédent
                                </span>
                            )}

                            <span
                                className={classnames("page-number", {
                                    "active": page === 1,
                                })}
                                onClick={() => changePage(1)}
                            >
                                1
                            </span>
                            {page - pageEllipsisInterval > 1 &&
                                <span className="mr-2 d-inline-block">...</span>
                            }
                            {range(2, pageCount).map(
                                (i) =>
                                    page - pageEllipsisInterval <= i &&
                                    i <= page + pageEllipsisInterval &&
                                    i !== pageCount && (
                                        <span
                                            key={i}
                                            className={classnames("page-number", {
                                                "active": i === page,
                                            })}
                                            onClick={() => changePage(i)}
                                        >
                                            {i}
                                        </span>
                                    )
                            )}
                            {page + pageEllipsisInterval < pageCount &&
                                <span className="mr-2 d-inline-block">...</span>
                            }
                            <span
                                className={classnames("page-number", {
                                    "active": page === pageCount,
                                })}
                                onClick={() => changePage(pageCount)}
                            >
                                {pageCount}
                            </span>

                            {page !== pageCount && (
                                <span className="page-next" onClick={() => changePage(page + 1)}>
                                    Suivant
                                </span>
                            )}
                        </div>
                    ) : null}
                </React.Fragment>
            ) : <Spinner />}
        </div>
    );
};

List.displayName = 'List';

List.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    fetching: PropTypes.bool,
    renderRow: PropTypes.func.isRequired,
    renderHeader: PropTypes.func
};
