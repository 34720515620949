import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteNotification } from 'actions';

import NotificationMessage from './Message.jsx';

class NotificationList extends React.Component {
    constructor() {
        super();

        this.state = {

        };
    }

    render() {
        const style = {
            position: "fixed",
            zIndex: "9999",
            left: "50%",
            top: "15px",
            transform: "translateX(-50%)"
        };

        if (!this.props.notifications)
            return null;

        return (
            <div style={style}>
                {this.props.notifications.map((notification, index) => (
                    <NotificationMessage
                        key={index}
                        uniqueKey={index}
                        index={index}
                        notification={notification}
                        deleteNotification={this.props.deleteNotification}
                    />
                ))}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        notifications: state.notifications
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        deleteNotification 
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
