import React from 'react';
import PropTypes from 'prop-types';

/**
 * ExitCircle Functional Component.
 */
export default function ExitCircle(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 25"><g fill="none" fillRule="evenodd"><circle fill="#4A4A4A" cx="12.5" cy="12.5" r="12.5"/><path d="M16.608 8.392l-8.16 8.16M16.667 16.667L8.333 8.333" stroke="#FFF" strokeLinecap="square"/></g></svg>
    );
};

ExitCircle.displayName = 'ExitCircle';

ExitCircle.propTypes = {
};
