import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Dropdown Component.
 */
export default class Dropdown extends Component {
    /**
     * Creates an instance of Dropdown.
     * @param {Object} props Component props
     */
    constructor(props) {
        super(props);

        this.state = {
            open: false
        }
    }

    /**
     * React lifecycle.
     */
    componentDidMount() {
        document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
    }

    /**
     * React lifecycle.
     */
    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({open: false});
        }
    }

    /**
     * React lifecycle.
     */
    render() {
        const { 
            wrapperClassName,
            target, 
            targetClassName,
            children, 
            position, 
            hover
        } = this.props;
        const { open } = this.state;

        const style = {
            display: open ? 'block' : 'none',
            visibility: open ? 'visible' : 'hidden'
        };

        return (
            <div 
                className={classnames("dropdown", {
                    [wrapperClassName]: wrapperClassName
                })}
                onMouseEnter={hover && (() => this.setState({open: true}))}
                onMouseLeave={hover && (() => this.setState({open: false}))}
                ref={(node) => this.wrapperRef = node}
            >
                <span 
                    className={targetClassName}
                    onClick={() => this.setState({open: !open})}
                >
                    {target}
                </span>
                <div 
                    className={`dropdown menu ${position || 'left'}`} 
                    style={style}
                >
                    {children}
                </div>
            </div>
        );
    }
}

Dropdown.propTypes = {
    children: PropTypes.node.isRequired
};
