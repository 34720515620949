import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';

import {getNanos} from 'actions/index.js';

import Dropdown from 'components/Dropdown';
import Spinner from 'components/Spinner';

function mapStateToProps(state) {
    return {
        token: state.auth.data.token,
        nanos: state.nanos.data,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getNanos: (...args) => dispatch(getNanos(...args)),
    };
}

function SelectDropdown({match, token, getNanos, nanos}) {
    useEffect(() => {
        getNanos(token);
    }, []);

    return (
        <Dropdown 
            target="Nouvelle analyse"
            targetClassName="btn pill btn-primary"
            wrapperClassName="d-inline-block"
            position="right"
        >
            {!nanos && <Spinner />}
            {nanos && nanos.data.length === 0 && 
                <div className="dropdown-item disabled">Pas de jetson nanos trouvées</div>
            }
            {nanos && nanos.data.length > 0 && nanos.data.map((nano) => (
                <Link 
                    className="dropdown-item" 
                    key={nano.id}
                    to={`${match.url}/new?nano_id=${nano.id}`}
                >
                    {nano.name} (#{nano.id})
                </Link>
            ))}
        </Dropdown>
    );
};

SelectDropdown.displayName = 'SelectDropdown';

SelectDropdown.propTypes = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectDropdown));
