import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import {VALID_MIMETYPES} from 'constants';

import Upload from 'components/Icons/Upload';

export default function DropzoneField({value, onChange, onError}) {
    const [fetching, setFetching] = useState(false);

    const onDrop = (acceptedFiles) => {
        const images = [...value];

        setFetching(true);

        const promises = acceptedFiles.map((file, index) => {
            return new Promise((resolve, reject) => {
                if (VALID_MIMETYPES.indexOf(file.type) === -1) {
                    reject("Type de fichier non supporté. Seulement ces formats sont acceptés : png, jpg, jpeg.");
                }
                if (images.findIndex(image => image.file.name === file.name) > -1) {
                    reject(`Le fichier ${file.name} a déjà été sélectionné.`);
                }

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve({file: file, base64: reader.result});
                reader.onerror = (error) => reject("Une erreur est survenue lors du traitement des images.");
            });
        });

        Promise.all(promises).then(res => {
            setFetching(false);
            onChange(images.concat(res));
        }).catch(err => {
            setFetching(false);
            onError && onError(err);
        });
    };

    return (
        <Dropzone onDrop={onDrop}>
            {({getRootProps, getInputProps}) => (
                <div className="dropzone w-100 p-5" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="label">
                        <Upload className="mb-4" />
                        {fetching 
                            ? <div>Traitement...</div>
                            : <div>Déposez des fichiers ici ou cliquer pour sélectionner.</div>
                        }
                    </div>
                </div>
            )}
        </Dropzone>
    );
};

DropzoneField.displayName = 'DropzoneField';

DropzoneField.propTypes = {
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired
};
