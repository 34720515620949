import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getTime } from 'helpers';

import SortableColumn from 'components/Dashboard/SortableColumn';

export function RowHeader({}) {
    return (
        <div className="Header">
            <SortableColumn className="flex--1 item" name="id">
                ID
            </SortableColumn>
            <SortableColumn className="flex--2 item" name="qr_code">
                QR code
            </SortableColumn>
            <SortableColumn className="flex--2 item" name="client_group">
                Créateur
            </SortableColumn>
            <SortableColumn className="flex--2 item" name="created_by_nano_name">
                Jetson Nano
            </SortableColumn>
            <SortableColumn className="flex--2 item" name="created_at">
                Date de création
            </SortableColumn>
            <div className="flex--2 text-right">
                Pages
            </div>
        </div>
    );
}

/**
 * Row Component.
 */
export default class Row extends Component {
    /**
     * Creates an instance of Row.
     * @param {Object} props Component props
     */
    constructor(props) {
        super(props);

        this.state = {
            more: false,
        }
    }

    toggle() {
        const more = this.state.more;
        this.setState({more: !more});
    }

    /**
     * React lifecycle.
     */
    render() {
        const { data, onClick, match, history } = this.props;
        const { more } = this.state;
        let className = "Row";

        if (onClick) {
            className += " clickable";
        }

        return (
            <React.Fragment>
                <div className={className} onClick={() => onClick(data)}>
                    <div className="flex--1">
                        {data.id}
                    </div>
                    <div className="flex--2">
                        {data.qr_code}
                    </div>
                    <div className="flex--2">
                        {data.created_by_user && data.created_by_user.username}
                    </div>
                    <div className="flex--2">
                        {data.created_by_nano && 
                            <span>
                                {data.created_by_nano && data.created_by_nano.name}
                            </span>
                        }
                    </div>
                    <div className="flex--2">
                        {getTime(data.created_at)}
                    </div>
                    <div className="flex--2 text-right">
                        {data.analyses && data.analyses.length > 0 && (
                            <button
                                className="btn pill btn-action"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.toggle();
                                }}
                            >
                                {data.analyses.length}
                                <i className={classnames("ml-2 fas", {
                                    "fa-angle-down": !more,
                                    "fa-angle-up": more,
                                })}></i>
                            </button>
                        )}
                    </div>
                </div>

                {more && data.analyses && (
                    <div className="List sub">
                        {data.analyses.map((item, index) => (
                            <Row 
                                key={item.id || index}
                                data={item} 
                                onClick={(item) => history.push(`${match.url}/${item.id}`)}
                            />
                        ))}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

Row.propTypes = {
};
