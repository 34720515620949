import React from 'react';
import PropTypes from 'prop-types';

import { getTime } from 'helpers';

import SortableColumn from 'components/Dashboard/SortableColumn';

export function RowHeader({}) {
    return (
        <div className="Header">
            <SortableColumn className="flex--1 item" name="id">
                ID
            </SortableColumn>
            <SortableColumn className="flex--2 item" name="name">
                Nom
            </SortableColumn>
            <SortableColumn className="flex--2 item" name="client_group">
                Créateur
            </SortableColumn>
            <SortableColumn className="flex--1 item" name="mean">
                Moyenne
            </SortableColumn>
            <SortableColumn className="flex--1 item" name="std">
                Écart-type
            </SortableColumn>
            <SortableColumn className="flex--2 item" name="created_at">
                Date de création
            </SortableColumn>
        </div>
    );
}

/**
 * Row Functional Component.
 */
export default function Row({data, onClick}) {
    let className = "Row";
    if (onClick) {
        className += " clickable";
    }

    return (
        <div className={className} onClick={() => onClick(data)}>
            <div className="flex--1">
                {data.id}
            </div>
            <div className="flex--2">
                {data.name}
            </div>
            <div className="flex--2">
                {data.created_by_user && data.created_by_user.username}
            </div>
            <div className="flex--1">
                {data.mean}
            </div>
            <div className="flex--1">
                {data.std}
            </div>
            <div className="flex--2">
                {getTime(data.created_at)}
            </div>
        </div>
    );
};

Row.displayName = 'Row';

Row.propTypes = {
};
