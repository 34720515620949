import React from 'react';
import PropTypes from 'prop-types';

export default function Form({children, disabled, errors, onSubmit, className}) {
    return (
        <form className={"Form " + className} onSubmit={onSubmit}>
            {errors && (
                <div className="errors">
                    {errors.map((error) => <p>{error}</p>)}
                </div>
            )}
            <div className={disabled ? "content w-100 disabled" : "content w-100"}>
                {children}
            </div>
        </form>
    );
};

Form.displayName = 'Form';

Form.propTypes = {
    disabled: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string)
};
