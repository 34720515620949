import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useForm} from "react-hook-form";
import classnames from 'classnames';

import {addNotification, cleardrain} from 'actions';

import Screen from 'components/Screen';
import Form from 'components/Form';
import Button from 'components/Button';

function mapStateToProps(state) {
    return {
        token: state.auth.data.token
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addNotification: (...args) => dispatch(addNotification(...args)),
        create: (...args) => dispatch(cleardrain.create(...args))
    };
}

function CleardrainForm({addNotification, create, token}) {
    const [fetching, setFetching] = useState(false);
    const [picture, setPicture] = useState(null);
    const {register, handleSubmit, errors} = useForm();

    const onSubmit = (values) => {
        setFetching(true);

        create(token, {image: picture}, {
            success: () => {
                setFetching(false);
                addNotification({
                    type: 'success',
                    message: 'Analyse créee',
                });
            },
            failed: () => setFetching(false)
        });
    };

    const onFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);     
        reader.onloadend = () => setPicture(reader.result);
    };

    return (
        <Screen title="Créer une analyse Line Width">
            <Form 
                className="p-3 w-100 d-flex justify-content-center align-items-center"
                onSubmit={handleSubmit(onSubmit)}
                disabled={fetching}
            >
                <input
                    type='file'
                    name="file"
                    accept='.jpg, .png, .jpeg'
                    onChange={(e) => onFileChange(e)}
                    className={classnames("w-100", {
                        "mb-4": !picture,
                        "mb-2": picture
                    })}
                />
                {picture && (
                    <img src={picture} className="img-fluid mb-4" />
                )}
                <Button type="submit" className="btn btn-primary">
                    Envoyer
                </Button>
            </Form>
        </Screen>
    );
};

CleardrainForm.displayName = 'CleardrainForm';

CleardrainForm.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(CleardrainForm);
