import React from 'react';
import PropTypes from 'prop-types';

import history from '../../history';

import Modal from 'components/Modal';
import Button from 'components/Button';

export default function DeskFormConfirmModal({onConfirm}) {
    return (
        <Modal open className="p-4 d-block">
            <div className="text-center mb-4">
                Confirmer le lancement de l'analyse ?<br />
                Vérifiez le bon placement de la feuille sous la caméra avant de continuer.
            </div>
            <div className="text-center">
                <Button onClick={() => history.goBack()} className="btn pill btn-secondary mr-4">
                    Annuler
                </Button>

                <Button
                    className="btn pill btn-primary"
                    onClick={() => onConfirm()}
                >
                    Continuer
                </Button>
            </div>
        </Modal>        
    );
};

DeskFormConfirmModal.displayName = 'DeskFormConfirmModal';

DeskFormConfirmModal.propTypes = {
    onConfirm: PropTypes.func.isRequired
};
