import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { 
    mileage, 
    changeMileageState,
    updateMileage,
    getMileagePictures, 
    getMileagePicture,
    updateMileagePicture,
    getAllMileage,
    addNotification,
} from 'actions';
import {getTime, getRange, getURLParam, setURLParam, timeIntegersToDate} from 'helpers';

import ScreenView from 'components/Screen/View';
import List from 'components/List';
import Row, { RowHeader } from 'components/Dashboard/Mileage/RowPicture';
import MileageStatus from 'components/Dashboard/Mileage/Status';
import MileageFormResume from 'components/Dashboard/Mileage/FormResume';
import Spinner from 'components/Spinner';
import Modal from 'components/Modal';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import IconStop from 'components/Icons/Stop';

function mapStateToProps(state) {
    return {
        token: state.auth.data.token
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeMileageState: (...args) => dispatch(changeMileageState(...args)),
        updateMileage: (...args) => dispatch(updateMileage(...args)),
        getMileage: (...args) => dispatch(mileage.read(...args)),
        deleteMileage: (...args) => dispatch(mileage.delete(...args)),
        getPictures: (...args) => dispatch(getMileagePictures(...args)),
        getPicture: (...args) => dispatch(getMileagePicture(...args)),
        getAllMileage: (...args) => dispatch(getAllMileage(...args)),
        updateMileagePicture: (...args) => dispatch(updateMileagePicture(...args)),
        addNotification: (...args) => dispatch(addNotification(...args)),
    };
}

export class ScreensView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mileage: null,
            pictures: null,
            picture: null
        };
    }

    componentDidMount() {
        if (!getURLParam("page")) {
            setURLParam("page", 1);
        }

        if (!getURLParam("page_size")) {
            setURLParam("page_size", 25);
        }

        this.getMileage();
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress, false);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location !== prevProps.location) {
            this.getPictures();
        }
    }

    getMileage() {
        const { token, match } = this.props;

        this.props.getMileage(token, match.params.id, {
            success: (response) => {
                this.setState({mileage: response.data});
                this.getPictures();
            }
        });
    }

    getPictures() {
        const { token, match } = this.props;
        const { mileage } = this.state;

        this.props.getPictures(token, match.params.id, '/pictures', window.location.search, {
            success: (response) => {
                let _mileage = mileage;

                this.setState({pictures: response.data});
                if (response.data && response.data.data.length !== 0) {
                    this.setState({mileage: response.data.data[0].machine_mileage});
                    _mileage = response.data.data[0].machine_mileage;
                }

                if (_mileage && _mileage.is_currently_running) {
                    setTimeout(() => this.getPictures(), 3000);
                }
            },
            failed: (error) => console.log(error)
        });
    }

    showPicture(item) {
        const { getPicture, token } = this.props;
        
        document.addEventListener('keydown', this.handleKeyPress, false);

        this.setState({picture: item});

        getPicture(token, item.id.toString(), '/picture?base64=true', {
            success: (response) => {
                // User may have closed the picture before the call is finished.
                if (this.state.picture && item.id === this.state.picture.id) {
                    this.setState({picture: {
                        ...this.state.picture, 
                        image: response.data.image
                    }});
                }
            }
        });
    }

    getPicture(id) {
        const { getPicture, token } = this.props;

        getPicture(token, id.toString(), {
            success: ({data: picture}) => {
                this.setState({picture: {
                    ...picture,
                    isNavigationDisabled: true
                }});

                getPicture(token, id.toString(), '/picture?base64=true', {
                    success: ({data: dataPic}) => {
                        // User may have closed the picture before the call is finished.
                        if (this.state.picture && id === this.state.picture.id) {
                            this.setState({picture: {
                                ...picture,
                                image: dataPic.image,
                                isNavigationDisabled: true
                            }});
                        }
                    }
                });
            }
        });
    }

    closePicture() {
        this.setState({picture: null});
        document.removeEventListener('keydown', this.handleKeyPress, false);
    }

    nextPicture() {
        const { pictures, picture } = this.state;
        const currentIndex = pictures.data.findIndex(pic => pic.id === picture.id);
        if (currentIndex !== -1 && currentIndex < pictures.data.length - 1) {
            this.closePicture();
            this.showPicture(pictures.data[currentIndex + 1]);
        }
    }

    previousPicture() {
        const { pictures, picture } = this.state;
        const currentIndex = pictures.data.findIndex(pic => pic.id === picture.id);
        if (currentIndex !== -1 && currentIndex > 0) {
            this.closePicture();
            this.showPicture(pictures.data[currentIndex - 1]);
        }
    }

    stop() {
        const { token, match } = this.props;

        this.props.addNotification({
            type: 'info',
            message: 'Arrêt de l\'analyse...',
        });
        this.props.changeMileageState(
            token, 
            {}, 
            match.params.id, 
            '/stop', 
            {
                success: () => {
                    setTimeout(() => {
                        this.getMileage();
                    }, 1000);
                }
            }
        );
    }

    resume(values) {
        const { token, match } = this.props;

        let data = {};
        if (values.end_at_hour) {
            data.end_at = timeIntegersToDate(
                values.end_at_hour, 
                values.end_at_minute, 
                values.end_at_second
            );
        }

        this.props.addNotification({
            type: 'info',
            message: 'Relancement de l\'analyse...',
        });
        this.props.changeMileageState(
            token, 
            data, 
            match.params.id, 
            '/resume', 
            {success: () => {
                this.getMileage();
            }}
        );
    }

    changeEndOfTraces(trace, picture) {
        const { token, match, updateMileage } = this.props;
        const { mileage } = this.state;

        const data = {
            end_of_traces: mileage.end_of_traces.map(p => p ? p.id : null)
        };
        if (data.end_of_traces[trace] === picture.id) {
            data.end_of_traces[trace] = null;
        } else {
            data.end_of_traces[trace] = picture.id;
        }

        updateMileage(
            token, 
            data, 
            match.params.id, 
            {
                success: ({data: mileage}) => {
                    this.setState({mileage});

                    if (mileage.end_of_traces[trace] && mileage.end_of_traces[trace].id === picture.id) {
                        this.changeTraceState(picture, trace, "finished");
                    } else {
                        this.changeTraceState(picture, trace, "running");
                    }
                }
            }
        );
    }

    changeTraceState(picture, trace_index, trace_state) {
        const { token, match, updateMileagePicture } = this.props;
        const { picture: currentPicture } = this.state;

        const new_traces_state = picture.traces_state;
        new_traces_state[trace_index] = trace_state;

        updateMileagePicture(
            token, 
            { traces_state: new_traces_state }, 
            picture.id, 
            {
                success: ({data}) => {
                    if (data.id === currentPicture.id) {
                        this.setState({
                            picture: {
                                ...currentPicture,
                                ...data
                            }
                        });
                        this.getPictures();
                    }
                }
            }
        );
    }

    handleKeyPress = (e) => {
        switch (e.keyCode) {
            case 27: // esc
                this.closePicture();
                return;
            case 39: // left arrow
                this.nextPicture();
                return;
            case 37: // right arrow
                this.previousPicture();
                return;
            default:
                return;
        }
    };

    getTraceState(mileage, picture, trace_index) {
        let state = "";

        if (!picture) {
            return "";
        }

        const other_pic = mileage.end_of_traces ? mileage.end_of_traces[trace_index] : null;

        if (other_pic) {
            const picture_time = new Date(picture.created_at).getTime();
            const other_pic_time = new Date(other_pic.created_at).getTime();

            if (other_pic.traces_state[trace_index] && other_pic_time < picture_time) {
                state = other_pic.traces_state[trace_index];
            } else {
                state = picture.traces_state[trace_index];
            }
        } else {
            state = picture.traces_state[trace_index];
        }

        if (state === "finished") {
            return "Finie";
        }

        return "";
    }

    /**
     * React lifecycle.
     */
    render() {
        const { 
            token, 
            deleteMileage, 
            match, 
            history, 
            dispatch 
        } = this.props;
        const { 
            mileage, 
            pictures, 
            picture 
        } = this.state;

        if (!pictures) {
            return <Spinner />;
        }

        return (
            <ScreenView
                title={`Analyse Mileage ${match.params.id}`}
                onDelete={() => deleteMileage(token, mileage.id, {
                    success: () => getAllMileage(token)
                })}
                actions={mileage && mileage.is_currently_running 
                    ? (
                        <button
                            className="btn pill btn-secondary mr-4"
                            onClick={() => this.stop()}
                        >
                            Arrêter
                        </button>
                    ) 
                    : (
                        <Dropdown 
                            wrapperClassName="d-inline-block"
                            target="Continuer"
                            targetClassName="btn pill btn-secondary mr-4"
                            position="center"
                        >
                            <MileageFormResume
                                onSubmit={(values) => this.resume(values)}
                                initialValues={{
                                    end_at_second: 0
                                }}
                            />
                        </Dropdown>
                    )
                }
                editLink={false}
            >
                {mileage ? (
                    <div className="mileage__info">
                        <div className="p-3 detail d-flex align-item-center">
                            <div className="flex--1">{mileage.qr_code}</div>
                            <div className="flex--2">
                                <MileageStatus 
                                    className="justify-content-center"
                                    startAt={mileage.start_at} 
                                    running={mileage.is_currently_running} 
                                />
                            </div>
                            <div className="flex--5 text-right">
                                Fin : {mileage.end_at ? getTime(mileage.end_at) : "-"}
                            </div>
                        </div>

                        <div className="p-3">
                            <div className="row">
                                <div className="col-md-2">Fin des traces :</div>
                                <div className="col-md-10">
                                    <table className="end_of_trace_table">
                                        <thead>
                                            <tr>
                                                {getRange(10).map((_, index) =>
                                                    <td key={index}>
                                                        {index + 1}
                                                    </td>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                {getRange(10).map((_, index) =>
                                                    <td key={index}>
                                                        {mileage.end_of_traces[index] && 
                                                            <React.Fragment>
                                                                <a
                                                                    href=""
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.getPicture(
                                                                            mileage.end_of_traces[index].id
                                                                        );
                                                                    }}
                                                                >
                                                                    #{mileage.end_of_traces[index].id}
                                                                </a> : {mileage.end_of_traces[index].distance}m
                                                            </React.Fragment>
                                                        }
                                                    </td>
                                                )}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <hr className="w-100 my-0 hard" />

                        <List
                            renderRow={({item}) => (
                                <Row 
                                    data={item} 
                                    onClick={(item) => this.showPicture(item)}
                                    selected={picture && item.id === picture.id}
                                />
                            )}
                            renderHeader={() => <RowHeader />}
                            data={pictures && pictures.data}
                            fetching={pictures ? false : true}
                            pageCount={pictures && pictures.page_count}
                            history={history}
                        />
                    </div>
                ) : <Spinner />}

                {picture && (
                    <Modal
                        open={picture ? true : false}
                        onClose={() => this.closePicture()}
                    >
                        <div className="p-3 header d-flex align-items-center">
                            {!picture.isNavigationDisabled &&
                                <div className="flex--1">
                                    <button 
                                        onClick={() => this.previousPicture()}
                                        className="btn btn-secondary"
                                    >
                                        Précédente
                                    </button>
                                </div>
                            }
                            <h5 className="mb-0 flex--1 text-center">ID : {picture.id}</h5>
                            {!picture.isNavigationDisabled &&
                                <div className="flex--1 text-right">
                                    <button 
                                        onClick={() => this.nextPicture()}
                                        className="btn btn-secondary"
                                    >
                                        Suivante
                                    </button>
                                </div>
                            }
                        </div>
                        <div className="preview" style={{minHeight: "350px", minWidth: "700px"}}>
                            {picture.image ? (
                                <img 
                                    src={`data:image/jpg;base64,${picture.image}`}
                                    className="img-fluid" 
                                />
                            ) : <Spinner />}
                        </div>
                        {(picture.traces_state || picture.analysis_sale || picture.analysis_irreg) && (
                            <div className="p-4">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex--1">Trace n°</div>
                                    {getRange(10).map((item, index) => (
                                        <div className="flex--1 text-center" key={index}>
                                            <b>{index + 1}</b>
                                        </div>
                                    ))}
                                </div>

                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex--1">État</div>
                                    {getRange(10).map((item, index) => (
                                        <div className="flex--1 text-center" key={index}>
                                            {this.getTraceState(mileage, picture, index)}
                                        </div>
                                    ))}
                                </div>

                                {picture.analysis_sale && (
                                    <div className="d-flex align-items-center mb-2">
                                        <div className="flex--1">Sale</div>
                                        {picture.analysis_sale && picture.analysis_sale.map((item, index) => (
                                            <div className="flex--1 text-center" key={index}>
                                                {item}
                                            </div> 
                                        ))}
                                    </div>
                                )}

                                {picture.analysis_irreg && (
                                    <div className="d-flex align-items-center mb-2">
                                        <div className="flex--1">Irreg</div>
                                        {picture.analysis_irreg && picture.analysis_irreg.map((item, index) => (
                                            <div className="flex--1 text-center" key={index}>
                                                {item}
                                            </div> 
                                        ))}
                                    </div>
                                )}

                                <div className="d-flex align-items-center">
                                    <div className="flex--1"></div>
                                    {getRange(10).map((item, index) => (
                                        <Button 
                                            key={index} 
                                            className={classnames("flex--1 text-center btn btn-stop", {
                                                "active": mileage && mileage.end_of_traces[index] &&
                                                    mileage.end_of_traces[index].id === picture.id
                                            })}
                                            title={`Marquer cette image comme fin de la trace ${index + 1}`}
                                            onClick={() => this.changeEndOfTraces(index, picture)}
                                        >
                                            <IconStop width="20" height="20" />
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Modal>
                )}
            </ScreenView>
        );
    }
}

ScreensView.propTypes = {
    stateProp: PropTypes.string,
    stateAction: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreensView);
