import React from 'react';
import PropTypes from 'prop-types';

/**
 * ValidateCircle Functional Component.
 */
export default function ValidateCircle(props) {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g fillRule="nonzero" fill="none"><path d="M256.019 0C114.999 0 .277 114.722.277 255.746.277 396.766 115 511.492 256.02 511.492c141.016 0 255.737-114.726 255.737-255.746C511.756 114.722 397.035 0 256.02 0z" fill="#81B846"/><path d="M361.767 174.187l-145.592 145.43-65.909-65.834c-3.26-3.256-8.541-3.256-11.805 0a8.332 8.332 0 000 11.792l71.812 71.73a8.324 8.324 0 005.902 2.44 8.324 8.324 0 005.903-2.44l151.494-151.326a8.332 8.332 0 000-11.792 8.354 8.354 0 00-11.805 0z" fill="#FFF"/></g></svg>
    );
};

ValidateCircle.displayName = 'ValidateCircle';

ValidateCircle.propTypes = {
};
