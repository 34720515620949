import axios from 'axios';
import urlJoin from 'url-join';

export const URL = process.env.NODE_ENV === 'production'
    ? 'https://bic.psycle.io/api/v3/'
    : 'http://localhost:8000/api/v3/';
    
// TODO: not useful anymore because of oauth.
export const webToken = process.env.NODE_ENV === 'production' 
    ? '8c8ec255-600a-4e6e-bdc5-b8d2e1260667'
    : 'web_platform';
export const CLIENT_ID = 'ea8eac85-9aa5-4888-93e7-0cc79dec752e';

function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const csrftoken = getCookie('csrftoken');

export function get(endpoint, token = webToken, id = '', action = '', query = '') {
    return axios({
        method: 'get',
        url: urlJoin(URL, endpoint, id, action, query),
        headers: {
            'Authorization': 'Bearer ' + token,
        }
    });
}

export function post(endpoint, token = webToken, data, id = '', action = '', query = '') {
    return axios({
        method: 'post',
        url: urlJoin(URL, endpoint, id, action, query),
        data,
        headers: {
            'Authorization': 'Bearer ' + token,
            "X-CSRFToken": csrftoken
        }
    });
}

export function put(endpoint, token = webToken, data, id = '', action = '', query = '') {
    return axios({
        method: 'put',
        url: urlJoin(URL, endpoint, id.toString(), action, query),
        data,
        headers: {
            'Authorization': 'Bearer ' + token,
            "X-CSRFToken": csrftoken
        }
    });
}

export function destroy(endpoint, token = webToken, id = '', action = '', query = '') {
    return axios({
        method: 'delete',
        url: urlJoin(URL, endpoint, id.toString(), action, query),
        headers: {
            'Authorization': 'Bearer ' + token,
        }
    });
}
