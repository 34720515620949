import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

import ValidateCircle from 'components/Icons/ValidateCircle'
import PlannedCircle from 'components/Icons/PlannedCircle';
import Spinner from 'components/Spinner';

/**
 * MileageStatus Functional Component.
 */
export default function MileageStatus({className, startAt, running}) {
    let status = null;

    if (new Date().valueOf() <= (moment.utc(startAt).local().toDate().valueOf() + 3000)) {
        status = (
            <React.Fragment>
                <PlannedCircle className="mr-4" />
                Prévu
            </React.Fragment>
        );
    } else if (running) {
        status = (
            <React.Fragment>
                <Spinner 
                    className="mr-4 d-inline-block blue" 
                    style={{width: "24px", height: "24px"}} 
                    noCenter 
                /> 
                En cours
            </React.Fragment>
        );
    } else {
        status = (
            <React.Fragment>
                <ValidateCircle className="mr-4" />
                Terminé
            </React.Fragment>
        );
    }

    return (
        <div className={classnames("d-flex align-items-center", {
            [className]: className
        })}>
            {status}
        </div>
    );
};

MileageStatus.displayName = 'MileageStatus';

MileageStatus.propTypes = {
};
