import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import AuthRoute from 'components/Utilities/AuthRoute';
import Logout from 'components/Utilities/Logout';
import Error404 from 'screens/Error/404.jsx';
import NotificationList from 'components/Notification/List';

import Login from 'screens/Login';

import DeskMWList from 'screens/DeskMW/List';
import DeskMWForm from 'screens/DeskMW/Form';
import DeskMWView from 'screens/DeskMW/View';
import DeskEMList from 'screens/DeskEM/List';
import DeskEMForm from 'screens/DeskEM/Form';
import DeskEMView from 'screens/DeskEM/View';
import DeskDrainbackList from 'screens/DeskDrainback/List';
import DeskDrainbackForm from 'screens/DeskDrainback/Form';
import DeskDrainbackView from 'screens/DeskDrainback/View';
import DeskThicknessList from 'screens/DeskThickness/List';
import DeskThicknessForm from 'screens/DeskThickness/Form';
import DeskThicknessView from 'screens/DeskThickness/View';
import MileageList from 'screens/Mileage/List';
import MileageView from 'screens/Mileage/View';
import CleardrainForm from 'screens/Cleardrain/Form';
import SeepageForm from 'screens/Seepage/Form';

import NanoList from 'screens/Nano/List';

const routes = [
    // Desk MW
    {path: "/analyses/desk_mws", component: DeskMWList},
    {path: "/analyses/desk_mws/new", component: DeskMWForm},
    {path: "/analyses/desk_mws/:id", component: DeskMWView},
    {path: "/analyses/desk_mws/:id/edit", component: DeskMWForm},
    // Desk EM
    {path: "/analyses/desk_ems", component: DeskEMList},
    {path: "/analyses/desk_ems/new", component: DeskEMForm},
    {path: "/analyses/desk_ems/:id", component: DeskEMView},
    {path: "/analyses/desk_ems/:id/edit", component: DeskEMForm},
    // Desk Drainback
    {path: "/analyses/desk_drainbacks", component: DeskDrainbackList},
    {path: "/analyses/desk_drainbacks/new", component: DeskDrainbackForm},
    {path: "/analyses/desk_drainbacks/:id", component: DeskDrainbackView},
    {path: "/analyses/desk_drainbacks/:id/edit", component: DeskDrainbackForm},
    // Desk Thickness
    {path: "/analyses/desk_thicknesses", component: DeskThicknessList},
    {path: "/analyses/desk_thicknesses/new", component: DeskThicknessForm},
    {path: "/analyses/desk_thicknesses/:id", component: DeskThicknessView},
    // Mileage
    {path: "/analyses/mileages", component: MileageList},
    {path: "/analyses/mileages/:id", component: MileageView},
    // Others
    {path: "/analyses/cleardrains", component: CleardrainForm},
    {path: "/analyses/seepages", component: SeepageForm},    
    {path: "/nanos", component: NanoList}
];

export default function App({}) {
    return (
        <div className="App">
            <NotificationList />

            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />

                <AuthRoute exact path="/" render={() => <Redirect to="/analyses/desk_mws" />} />  
                <AuthRoute exact path="/analyses" render={() => <Redirect to="/analyses/desk_mws" />} />  

                {routes.map(({path, component: Component}, index) =>
                    <AuthRoute key={index} path={path} exact component={Component} />
                )}

                <Route component={Error404} />
            </Switch>
        </div>
    );
};

App.displayName = 'App';

App.propTypes = {
};
