import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Field = React.forwardRef(({error, className, ...props}, ref) => (
    <React.Fragment>
        <input 
            {...props} 
            ref={ref}
            className={classnames("form-control", {
                [className]: className, "error": error ? true : false
            })}
        />
        {error && error.message &&
            <span className="d-inline-block ml-2">{error.message}</span>
        }
    </React.Fragment>
));

Field.displayName = 'Field';

Field.propTypes = {
};

export default Field;
