import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SVG } from '@svgdotjs/svg.js';
import '@svgdotjs/svg.draggable.js';

import { drawRect, setupDrawRect } from 'helpers';

import Spinner from 'components/Spinner';
import Button from 'components/Button';

export default function NanoDrawCrops({src, crops, onSubmit, onRemove}) {
    const [originalImage, setOriginalImage] = useState(null);
    const [svgWrapperRect, setSvgWrapperRect] = useState(null);
    const [drawSVG, setDrawSVG] = useState(null);
    const [elements, setElements] = useState([]);
    const svgWrapperRef = useRef(null);

    const drawHandlers = {
        onSelect: (elt) => setElements(oldElements => [...oldElements]),  // Trigger re-render
        onDeselect: (elt) => setElements(oldElements => [...oldElements]),  // Trigger re-render
        onRemove: (elt) => {
            if (elt.data('id')) {
                onRemove(elt.data('id'));
            }
        }
    };

    // Draw setup.
    useEffect(() => {
        if (src) {
            const svgWrapper = svgWrapperRef.current;
            const image = new Image();
            image.onload = () => {
                svgWrapper.style.height = `${(svgWrapper.offsetWidth * image.height) / image.width}px`;
                svgWrapper.style.backgroundImage = `url('${image.src}')`;
                svgWrapper.style.backgroundSize = "cover";

                const rect = svgWrapper.getBoundingClientRect();
                setSvgWrapperRect({
                    x: rect.x,
                    y: rect.y,
                    bottom: rect.bottom,
                    left: rect.left,
                    right: rect.right,
                    top: rect.top,
                    width: rect.width,
                    height: (svgWrapper.offsetWidth * image.height) / image.width  // Make sure height is correct
                });
            };
            image.src = src;
            
            setOriginalImage(image);

            if (drawSVG) {
                drawSVG.remove();
            }
            setDrawSVG(SVG().addTo("#svg-wrapper").size('100%', '100%'));
        }
    }, [src]);

    // Setup drawing event handlers.
    useEffect(() => {        
        if (!svgWrapperRect|| !drawSVG) {
            return;
        }

        let drawSetup;
        const handlers = {
            ...drawHandlers,
            onStopDrawing: (elt) => {
                setElements(oldElements => [...oldElements, elt])
            }
        };

        drawSetup = setupDrawRect({
            drawSVG, 
            svgWrapperRect, 
            handlers
        });
        drawSetup.on();

        return () => {
            drawSetup.off();
        };
    }, [drawSVG, svgWrapperRect]);

    // Draw saved annotations.
    useEffect(() => {
        if (!crops || !drawSVG || !originalImage || !svgWrapperRect) {
            return;
        }

        // Clear all before settings new ones.
        drawSVG.clear();
        setElements([]);

        crops
            .filter(c => c.image_width === originalImage.width && c.image_height === originalImage.height)
            .forEach(c => {
                const x = (c.x1 * svgWrapperRect.width) / originalImage.width;
                const y = (c.y1 * svgWrapperRect.height) / originalImage.height;
                const width = ((c.x2 * svgWrapperRect.width) / originalImage.width) - x;
                const height = ((c.y2 * svgWrapperRect.height) / originalImage.height) - y;

                const rect = drawRect({
                    drawSVG,
                    svgWrapperRect,
                    x,
                    y,
                    width,
                    height,
                    handlers: drawHandlers
                });
                rect.data('id', c.id);
                setElements(oldElements => [...oldElements, rect]);
            });
    }, [crops, drawSVG, originalImage, svgWrapperRect]);

    function _onSubmit() {
        if (!drawSVG || !originalImage || !svgWrapperRect) {
            return;
        }

        let payload = [];

        drawSVG.children().forEach(element => {
            if (element.data('crop') && !element.data('disabled')) {
                const box = element.bbox();

                const getX = (x) => (x * originalImage.width) / svgWrapperRect.width;
                const getY = (y) => (y * originalImage.height) / svgWrapperRect.height;

                payload.push({
                    id: element.data('id'),
                    x1: getX(box.x),
                    y1: getY(box.y),
                    x2: getX(box.x2),
                    y2: getY(box.y2),
                    image_width: originalImage.width,
                    image_height: originalImage.height
                });
            }
        });

        onSubmit(payload);
    }

    return (
        <div>
            <div style={{width: "800px"}}>
                {src 
                    ? (
                        <div 
                            id="svg-wrapper"
                            ref={svgWrapperRef}
                            className="w-100"
                            style={{cursor: "crosshair"}}
                        ></div>
                    )
                    : <Spinner />
                }
            </div>
            <div className="mt-3 text-center">
                <Button className="btn btn-secondary" onClick={() => _onSubmit()}>
                    Enregistrer les zones
                </Button>
            </div>
        </div>
    );

    
    ;
};

NanoDrawCrops.displayName = 'NanoDrawCrops';

NanoDrawCrops.propTypes = {
    src: PropTypes.string,
    crops: PropTypes.arrayOf(PropTypes.object.isRequired),
    onSubmit: PropTypes.func
};
