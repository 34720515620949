import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import urlJoin from 'url-join';

import {deskmw, getAllDeskMW, getDeskMWPicture, addNotification} from 'actions';
import {getTime, getRange} from 'helpers';
import {URL, post} from '../../api';

import ScreenView from 'components/Screen/View';
import Spinner from 'components/Spinner';

function mapStateToProps(state) {
    return {
        token: state.auth.data.token,
        analysis: state.deskmw.data,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        reset: () => dispatch(deskmw.reset()),
        get: (...args) => dispatch(deskmw.read(...args)),
        destroy: (...args) => dispatch(deskmw.delete(...args)),
        getPicture: (...args) => dispatch(getDeskMWPicture(...args)),
        addNotification: (...args) => dispatch(addNotification(...args))
    };
}

function DeskMWView({match, token, analysis, reset, get, getPicture, destroy, addNotification}) {
    const [picture, setPicture] = useState(null);

    useEffect(() => {
        reset();
        get(token, match.params.id);
        getPicture(token, match.params.id, 'picture?base64=true&grid=true', {
            success: (response) => setPicture(`data:image/jpg;base64,${response.data.image}`),
            failed: () => setPicture(false)
        });
    }, []);

    function xmlTransfer() {
        post(`/desk_mws/${match.params.id}/transfer_xml`, token).then(() =>
            addNotification({
                type: 'success',
                message: 'Transfert effectué'
            })
        ).catch(() =>
            addNotification({
                type: 'danger',
                message: 'Erreur lors du transfert'
            })
        );
    }

    return (
        <ScreenView
            title={`Analyse ${match.params.id}`}
            onDelete={() => destroy(token, match.params.id, {
                success: () => getAll(token)
            })}
            actions={
                <React.Fragment>
                    <button
                        className="btn pill btn-secondary mr-4"
                        onClick={() => xmlTransfer()}
                    >
                        Transfert XML
                    </button>
                    <a
                        className="btn pill btn-secondary mr-4"
                        href={`${URL}desk_mws/${match.params.id}/xml?token=${token}`}
                        download={true}
                    >
                        Export XML
                    </a>
                </React.Fragment>
            }
            editLink
        >
            {analysis
                ? (
                    <div className="col-md-10 mx-auto">
                        <div className="info p-4">
                            <div className="mb-4">
                                <h4 className="font-weight-bold">Analyse Desk MW</h4>
                            </div>

                            <div className="mb-4">
                                <dl className="row">
                                    <dt className="col-sm-2">QR Code</dt>
                                    <dd className="col-sm-10 font-weight-bold">{analysis.qr_code}</dd>

                                    <dt className="col-sm-2 font-weight-normal">Remarque</dt>
                                    <dd className="col-sm-10">{analysis.remark}</dd>

                                    <dt className="col-sm-2 font-weight-normal">Date de création</dt>
                                    <dd className="col-sm-10">{getTime(analysis.created_at)}</dd>

                                    <dt className="col-sm-2 font-weight-normal">Utilisateur</dt>
                                    <dd className="col-sm-10">
                                        {analysis.created_by_user && `${analysis.created_by_user.username} (${analysis.created_by_user.role})`}
                                    </dd>

                                    <dt className="col-sm-2 font-weight-normal">Jetson Nano</dt>
                                    <dd className="col-sm-10">
                                        {analysis.created_by_nano &&
                                            <span>
                                                {analysis.created_by_nano.name} (#{analysis.created_by_nano.id})
                                            </span>
                                        }
                                    </dd>
                                </dl>
                            </div>

                            <div className="d-flex align-items-center mb-1">
                                <div className="flex--1"><b>Trace n°</b></div>
                                {getRange(10).map((item, index) => (
                                    <div className="d-flex flex--1 text-right align-items-center" key={`1-${index}`}>
                                        <b>{index + 1}</b>

                                        <div className="ml-3 text--small invisible" style={{whiteSpace: "nowrap"}}>
                                            10.0
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="d-flex align-items-center mb-2">
                                <div className="flex--1"><b>Sale</b></div>
                                {analysis.analysis_sale && analysis.analysis_sale.map((item, index) => (
                                    <div className="d-flex flex--1 text-right align-items-center" key={`1-${index}`}>
                                        {item}

                                        {analysis.original_analysis_sale &&
                                            <div className="ml-3 text--small text-muted" style={{whiteSpace: "nowrap"}}>
                                                ({Number(analysis.original_analysis_sale[index]).toFixed(1)})
                                            </div>
                                        }
                                    </div> 
                                ))}
                            </div>

                            <div className="d-flex align-items-center mb-2">
                                <div className="flex--1"><b>Irreg</b></div>
                                {analysis.analysis_irreg && analysis.analysis_irreg.map((item, index) => (
                                    <div className="d-flex flex--1 text-right align-items-center" key={`1-${index}`}>
                                        {item}

                                        {analysis.original_analysis_irreg &&
                                            <div className="ml-3 text--small text-muted" style={{whiteSpace: "nowrap"}}>
                                                ({Number(analysis.original_analysis_irreg[index]).toFixed(1)})
                                            </div>
                                        }
                                    </div> 
                                ))}
                            </div>
                        </div>
                        <div className="capture p-4 position-relative">
                            {picture && <img src={picture} className="img-fluid" />}
                            {picture === false && <div className='text-center'>Erreur lors de la récupération de l'image</div>}
                            {picture === null && <Spinner />}
                        </div>
                    </div>
                )
                : <Spinner />
            }
        </ScreenView> 
    );
};

DeskMWView.displayName = 'DeskMWView';

DeskMWView.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskMWView);
