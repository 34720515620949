import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * RenderField Functional Component.
 */
export default function RenderField({
    input,
    className,
    meta: {touched, error, warning},
    noLabel,
    ...inputProps
}) {
    return (
        <React.Fragment>
            <input 
                {...input} 
                {...inputProps}
                className={classnames(className, {error})}
            />
            {touched && warning && typeof warning === "string" && !noLabel && (
                <span className="d-inline-block ml-2">{warning}</span>
            )}
            {touched && error && typeof error === "string" && !noLabel && (
                <span className="d-inline-block ml-2">{error}</span>
            )}
        </React.Fragment>
    );
};

RenderField.displayName = 'RenderField';

RenderField.propTypes = {
};
