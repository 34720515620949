import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';

import { post, CLIENT_ID } from './../api';
import { LOGIN } from './../actions/types';
import { getURLParam } from 'helpers';

import Spinner from 'components/Spinner';
import Logo from 'components/Icons/Logo';

/**
 * Select props from Redux state.
 * @param {Object} state Current state
 */
function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

/**
 * ScreensLogin.
 */
export class ScreensLogin extends Component {
    /**
     * Creates an instance of ScreensLogin.
     * @param {Object} props Component props
     */
    constructor(props) {
        super(props);

        this.state = {
            form: {
                username: '',
                password: ''
            }
        };
    }

    getRedirectLink() {
        const redirect = getURLParam("redirect");

        if (redirect && redirect !== "/") {
            return `/${redirect}`;
        }

        return '/';
    }

    handleChange(e) {
        const form = this.state.form;
        form[e.target.name] = e.target.value;
        this.setState({form});
    }

    handleSubmit(e) {
        const { form } = this.state;
        const { dispatch } = this.props;

        e.preventDefault();

        dispatch({type: LOGIN + '_FETCHING'});
        
        post(
            'oauth/authenticate',
            '',
            {
                client_id: CLIENT_ID,
                ...form,
            }
        ).then(resAuth => post(
            'oauth/token',
            '',
            {
                client_id: CLIENT_ID,
                code: resAuth.data.code,
                grant_type: "code"
            }
        ).then(resToken => {
            dispatch({type: LOGIN + '_SUCCESS', payload: resToken.data});
        }).catch(error => {
            let msg = "";
            if (error.response && error.response.data && error.response.data.error) {
                msg = error.response.data.error;
            } 

            dispatch({type: LOGIN + '_FAILED', payload: msg});
        })).catch(errorAuth => {
            let msg = "";
            if (errorAuth.response && errorAuth.response.data && errorAuth.response.data.error) {
                msg = errorAuth.response.data.error;
            } 

            dispatch({type: LOGIN + '_FAILED', payload: msg});
        });
    }

    /**
     * React lifecycle.
     */
    render() {
        const error = this.props.auth.error && (
            <div className="alert alert-danger px-5">
                {this.props.auth.error || "Une erreur est survenue."}
            </div> 
        );

        const fetching = this.props.auth.fetching;

        if (this.props.auth && this.props.auth.data) {
            return <Redirect to={this.getRedirectLink()} />;
        }

        return (
            <div className="Login bg-light">
                <div className="row">
                    <div className="col-md-4 col-lg-3 mx-auto">
                        <div className="mx-auto my-4">
                            <Link to="/">
                                <div className="mx-auto" style={{ width:"150px" }}>
                                    <Logo />
                                </div>
                            </Link>
                        </div>

                        {error}
                        
                        <form 
                            className={fetching 
                                ? "form fetching mx-auto bg-white" 
                                : "form mx-auto bg-white"
                            }
                            onSubmit={(e) => this.handleSubmit(e)}
                        >
                            {fetching && (
                                <div className="fetching"><Spinner /></div>
                            )}

                            <div className="p-5">
                                <h5 className="font-weight-bold mb-4">Connexion</h5>
                                
                                <div className="form-group">
                                    <label className="d-block">Nom d'utilisateur</label>
                                    <input 
                                        type="text" 
                                        name="username"
                                        placeholder="utilisateur"
                                        className="form-control mb-2" 
                                        value={this.state.form.username}
                                        onChange={(e) => this.handleChange(e)}
                                        required 
                                    />
                                </div>

                                <div className="form-group">
                                    <label className="d-block">Mot de passe</label>
                                    <input 
                                        type="password" 
                                        name="password" 
                                        placeholder="mot de passe"
                                        className="form-control mb-4" 
                                        value={this.state.form.password}
                                        onChange={(e) => this.handleChange(e)}
                                        required
                                    />
                                </div>

                                <div className="w-100 text-center">
                                    <button type="submit" className="btn btn-secondary">
                                        Connexion
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

ScreensLogin.propTypes = {
    auth: PropTypes.object.isRequired
};

/**
 * ScreensLogin connected.
 */
export default connect(mapStateToProps)(ScreensLogin);
