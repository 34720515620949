import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import urlJoin from 'url-join';

import {deskdrainback, getDeskDrainbackPicture, getAllDeskDrainback} from 'actions';
import {getTime, getRange} from 'helpers';
import {URL} from '../../api';

import ScreenView from 'components/Screen/View';
import Spinner from 'components/Spinner';

function mapStateToProps(state) {
    return {
        token: state.auth.data.token,
        deskdrainback: state.deskdrainback.data,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        reset: () => dispatch(deskdrainback.reset()),
        get: (...args) => dispatch(deskdrainback.read(...args)),
        destroy: (...args) => dispatch(deskdrainback.delete(...args)),
        getPicture: (...args) => dispatch(getDeskDrainbackPicture(...args)),
        getAll: (...args) => dispatch(getAllDeskDrainback(...args)),
    };
}

function DeskEMView({match, token, deskdrainback, reset, get, getPicture, destroy}) {
    const [picture, setPicture] = useState(null);

    useEffect(() => {
        reset();
        get(token, match.params.id);
        getPicture(token, match.params.id, 'picture?base64=true&grid=true', {
            success: (response) => setPicture(`data:image/jpg;base64,${response.data.image}`)
        });
    }, []);

    return (
        <ScreenView
            title={`Analyse ${match.params.id}`}
            onDelete={() => destroy(token, match.params.id, {
                success: () => getAll(token)
            })}
        >
            {deskdrainback
                ? (
                    <div className="col-md-10 mx-auto">
                        <div className="info p-4">
                            <div className="mb-4">
                                <h4 className="font-weight-bold">Analyse Desk Drainback</h4>
                            </div>

                            <div className="mb-4">
                                <dl className="row">
                                    <dt className="col-sm-2">QR Code</dt>
                                    <dd className="col-sm-10 font-weight-bold">{deskdrainback.qr_code}</dd>

                                    <dt className="col-sm-2 font-weight-normal">Date de création</dt>
                                    <dd className="col-sm-10">{getTime(deskdrainback.created_at)}</dd>

                                    <dt className="col-sm-2 font-weight-normal">Utilisateur</dt>
                                    <dd className="col-sm-10">
                                        {deskdrainback.created_by_user && `${deskdrainback.created_by_user.username} (${deskdrainback.created_by_user.role})`}
                                    </dd>

                                    <dt className="col-sm-2 font-weight-normal">Jetson Nano</dt>
                                    {deskdrainback.created_by_nano && 
                                        <dd className="col-sm-10">
                                            {deskdrainback.created_by_nano.name} (#{deskdrainback.created_by_nano.id})
                                        </dd>
                                    }
                                </dl>
                            </div>

                            <div className="d-flex align-items-center mb-1">
                                <div className="flex--1"><b>Trace n°</b></div>
                                {getRange(8).map((item, index) => (
                                    <div className="flex--1 text-right" key={`1-${index}`}>
                                        <b>{index + 1}</b>
                                    </div>
                                ))}
                            </div>

                            <div className="d-flex align-items-center mb-2">
                                <div className="flex--1"><b>Haut</b></div>
                                {deskdrainback.grades && deskdrainback.grades.slice(0, 8).map((item, index) => (
                                    <div className="flex--1 text-right" key={`1-${index}`}>
                                        {item === -1 ? "Clogging" : item}
                                    </div> 
                                ))}
                            </div>

                            <div className="d-flex align-items-center mb-2">
                                <div className="flex--1"><b>Bas</b></div>
                                {deskdrainback.grades && deskdrainback.grades.slice(8, 16).map((item, index) => (
                                    <div className="flex--1 text-right" key={`1-${index}`}>
                                        {item === -1 ? "Clogging" : item}
                                    </div> 
                                ))}
                            </div>
                        </div>
                        <div className="capture p-4 position-relative">
                            {picture 
                                ? <img src={picture} className="img-fluid" />
                                : <Spinner />
                            }
                        </div>
                    </div>
                )
                : <Spinner />
            }
        </ScreenView> 
    );
};

DeskEMView.displayName = 'DeskEMView';

DeskEMView.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskEMView);
