const initialState = [];

export const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'NOTIFICATION_ADDED':
            let notifications = [...state];
            notifications.push(action.payload);
            return notifications;
        case 'NOTIFICATION_DELETED':
            notifications = [...state];
            if (action.payload) {
                notifications.splice(action.payload, 1);
            } else {
                notifications.splice(-1, 1);
            }
            return notifications;
        default:
            return state;
    }
};
