import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import history from '../../history';

export default function Header({title, actions, className}) {
    return (
        <div className={classnames("Header px-3 py-2 border-bottom", {[className]: className})}>
            <div className="d-inline-block">
                <button className="btn pill btn-secondary mr-4" onClick={() => history.goBack()}>
                    Retour
                </button>

                <b>{title}</b>
            </div>

            {actions &&
                <div className="d-inline-block">
                    {actions}
                </div>
            }
        </div>
    );
};

Header.displayName = 'Header';

Header.propTypes = {
    title: PropTypes.string.isRequired,
    actions: PropTypes.object,
    className: PropTypes.string
};
