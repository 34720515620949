import * as interact from 'interactjs';
import moment from 'moment';
import 'moment/locale/fr';
import 'url-search-params-polyfill';

import { STORAGE_AUTH_KEY } from 'constants';
import { post } from './api';
import history from './history';

/**
 * Generate an array of size n with a constant value
 * @param  {Number} n    
 * @param  {Object} value
 * @return {Array}
 */
export function getRange(n, value = null) {
    let range = [];
    for (let i = 0; i < n; i++) {
        range[i] = value;
    }
    return range;
}

/**
 * Returns a range of int between start and end.
 * @param  {Number} start
 * @param  {Number} end
 * @return {Array}
 */
export function range(start, end) {
    var ans = [];
    for (let i = start; i <= end; i++) {
        ans.push(i);
    }
    return ans;
}

/**
 * Returns a relative time
 * @param  {string} time
 * @return {Object}
 */
export function getTime(time) {
    moment.locale('fr');
    return moment.utc(time).local().format('DD/MM/YY à HH:mm');
}

/**
 * Apply set of filters to analyses
 * @param  {Array} filters
 * @param  {Array} analyses
 * @return {Array}    
 */
export function applyFilters(filters, analyses) {
    const filterData = (obj, key) => {
        if (typeof obj[key] === "string") {
            return obj[key].includes(filters[key]);
        }

        return false;
    }

    if (filters === null || filters === undefined) {
        return analyses;
    }

    Object.keys(filters).forEach(filterKey => {
        analyses = analyses.filter(obj => filterData(obj, filterKey));

        analyses = analyses.map(analysis => {
            if (analysis.analyses) {
                analysis.analyses = analysis.analyses.filter(obj => filterData(obj, filterKey));
            }
            return analysis;
        });
    });

    return analyses;
}

/**
 * Get param from URL search
 * @param  {String} key
 * @return {String || null}
 */
export function getURLParam(key) {
    const search = new URLSearchParams(history.location.search);
    return search.get(key);
}

/**
 * Set param for URL search
 * @param {String} key
 * @param {String} value
 */
export function setURLParam(key, value, method = "replace") {
    const search = new URLSearchParams(history.location.search);
    search.set(key, value);

    history[method]({
        pathname: history.location.pathname, 
        search: '?' + search.toString(),
    });
}

/**
 * Remove param from URL search
 * @param {String} key
 */
export function removeURLParam(key) {
    const search = new URLSearchParams(history.location.search);
    search.delete(key);

    history.replace({
        pathname: history.location.pathname, 
        search: '?' + search.toString(),
    });
}

/**
 * Converts input values to an array given the input has a prefix name.
 * @param  {Object} values The form values
 * @param  {String} prefixes The input name prefix, e.g "analysis--"
 * @return {Array}
 */
export function inputNamesToArray(values, prefixes = [], sizes = {}) {
    let data = {};
    prefixes.forEach(prefix => data[prefix] = [...getRange(sizes[prefix] || 10)]);

    Object.keys(values).forEach((key, index) => {
        prefixes.forEach(prefix => {
            if (key.startsWith(prefix)) {
                const localIndex = parseInt(key.split('-')[1]);
                if (values[key] === '') {
                    values[key] = null;
                }
                data[prefix][localIndex] = values[key];
                delete values[key];
            }
        });
    });

    return {
        ...values,
        ...data,
    };
}

/**
 * DeskMWForm input values to API format.
 * @param  {Object} values
 * @return {Object}
 */
export function deskMWToAPI(values) {
    let data = {
        analysis_sale: [...getRange(10)],
        analysis_irreg: [...getRange(10)],
        invalid: [...getRange(10)],
    };
    const initialKeys = Object.keys(data);

    Object.keys(values).forEach((key, index) => {
        let isInitialKey = false;

        initialKeys.forEach(arrKey => {
            if (key.startsWith(arrKey)) {
                const localIndex = parseInt(key.split('-')[1]);

                isInitialKey = true;
                
                if (values[key] === '') {
                    values[key] = null;
                }

                data[arrKey][localIndex] = values[key];
            }
        });

        if (!isInitialKey) {
            data[key] = values[key];
        }
    });

    return data;
}

export function validateNotes(values) {
    const errors = {
        analysis_sale: [],
        analysis_irreg: []
    };

    values.analysis_sale && values.analysis_sale.forEach((value, index) => {
        errors.analysis_sale[index] = false;
        if (["0", "2.5", "5", "7.5", "10", null, undefined].indexOf(value) === -1)  {
            errors.analysis_sale[index] = true;
        }
    });

    values.analysis_irreg && values.analysis_irreg.forEach((value, index) => {
        errors.analysis_irreg[index] = false;
        if (["0", "2.5", "5", "7.5", "10", null, undefined].indexOf(value) === -1)  {
            errors.analysis_irreg[index] = true;
        }
    });

    console.log(errors);

    return errors;
}

export function validateQrCode(form, values, dispatch, type) {
    const auth = JSON.parse(localStorage.getItem(STORAGE_AUTH_KEY));
    return post(type + '/check_qr_code', auth.token, {qr_code: values.qr_code}).then((response) => {
        if (response.data.exists === true) {
            dispatch({
                type: 'FORM_WARNING_ADD',
                form: form,
                payload: {
                    qr_code: "Ce QR code existe.",
                },
            });
        } else {
            dispatch({
                type: 'FORM_WARNING_REMOVE',
                form: form,
                payload: 'qr_code',
            });
        }
    }).catch((error) => {
        console.log(error);
    });
}

export function timeIntegersToDate(hours, minutes, seconds) {
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return moment.utc(date).format("YYYY-MM-DD HH:mm:ss+0000");
}

/**
 * Truncate a string to desired length and ending
 * @param  {String} str
 * @param  {Object} options
 * @return {String}
 */
export function truncate(str, options = {length: null, ending: null}) {
    let length = options.length || 10;
    let ending = options.ending || "...";

    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
}

export function setupDrawRect({drawSVG, svgWrapperRect, handlers = {}}) {
    let startPosition;
    let overlayRect;

    function handleStartDrawing(e) {
        startPosition = {
            x: e.clientX - svgWrapperRect.left,
            y: e.clientY - svgWrapperRect.top
        };

        if (!overlayRect) {
            overlayRect = drawSVG.rect(0, 0)
                .fill({opacity: 0.2}).stroke({color: '#000', width: 2, opacity: .5});
        }

        overlayRect.move(startPosition.x, startPosition.y);
    }

    function handleDrawing(e) {
        if (overlayRect) {
            const currentPosition = {
                x: e.clientX - svgWrapperRect.left,
                y: e.clientY - svgWrapperRect.top
            };

            overlayRect.move(
                currentPosition.x > startPosition.x ? startPosition.x : currentPosition.x,
                currentPosition.y > startPosition.y ? startPosition.y : currentPosition.y
            );
            overlayRect.width(Math.abs(currentPosition.x - startPosition.x));
            overlayRect.height(Math.abs(currentPosition.y - startPosition.y));
        }
    }

    function handleStopDrawing(e) {
        if (overlayRect) {
            overlayRect.remove();
            overlayRect = undefined;
        }

        // Prevent drawing new rect on rect dragend...
        if (e.target.parentNode === drawSVG.node) {
            return;
        }

        const currentPosition = {
            x: e.clientX - svgWrapperRect.left,
            y: e.clientY - svgWrapperRect.top
        };

        // Prevent adding very small rects (mis-clicks).
        if (Math.abs(currentPosition.x - startPosition.x) <= 2) {
            return;
        }

        const rect = drawRect({
            drawSVG,
            svgWrapperRect,
            x: currentPosition.x > startPosition.x ? startPosition.x : currentPosition.x,
            y: currentPosition.y > startPosition.y ? startPosition.y : currentPosition.y,
            width: Math.abs(currentPosition.x - startPosition.x),
            height: Math.abs(currentPosition.y - startPosition.y),
            handlers
        });

        if (handlers.onStopDrawing) {
            handlers.onStopDrawing(rect);
        }
    }

    function handleClick(e) {
        // If click on main svg, and not an element, deselect everything.
        if (e.target === drawSVG.node) {
            drawSVG.each(function() {
                this.fire('deselect');
            });
        }
    }

    return {
        on: () => {
            drawSVG.on('mousedown', handleStartDrawing);
            drawSVG.on('mousemove', handleDrawing);
            drawSVG.on('mouseup', handleStopDrawing);
            drawSVG.on('click', handleClick);
        },
        off: () => {
            drawSVG.off('mousedown', handleStartDrawing);
            drawSVG.off('mousemove', handleDrawing);
            drawSVG.off('mouseup', handleStopDrawing);
            drawSVG.off('click', handleClick);
        }
    }
}

export function drawRect({
    drawSVG,
    svgWrapperRect,
    x, 
    y, 
    width, 
    height, 
    disabled = false, 
    strokeColor = '#fff',
    handlers = {}
}) {
    if (!drawSVG || !svgWrapperRect) {
        return;
    }

    const rect = drawSVG.rect(width, height);
    rect.move(x, y);
    rect.fill({opacity: 0.2});
    rect.stroke({color: strokeColor, width: 2, opacity: 1});
    rect.css('touch-action', 'none');  // silence interactjs warning.

    const removeButton = drawSVG.rect(10, 10);
    removeButton.move(x + width - 5, y - 5);
    removeButton.fill({color: '#F56565'});
    removeButton.stroke({color: '#fff', width: 1});
    removeButton.css('cursor', 'pointer');
    removeButton.on('click', () => {
        if (handlers.onRemove) {
            handlers.onRemove(rect);    
        }

        rect.remove();
        removeButton.remove();
    });

    // Custom events.
    rect.on('select', () => {
        // Deselect all 
        drawSVG.each(function() {
            this.fire('deselect');
        });
        rect.stroke({color: '#02A9C7'});
        rect.data('selected', true);

        if (handlers.onSelect) {
            handlers.onSelect(rect);
        }
    });
    rect.on('deselect', () => {
        rect.stroke({color: strokeColor});
        rect.data('selected', false);

        if (handlers.onDeselect) {
            handlers.onDeselect(rect);
        }
    });

    if (!disabled) {
        rect.css('cursor', 'move');

        rect.on('click', (e) => {
            rect.fire('select');
        });
        rect.on('mousedown', (e) => {
            e.stopPropagation();
        })

        interact(rect.node)
            .resizable({
                edges: { left: true, right: true, bottom: true, top: true },
                listeners: {
                    move (event) {
                        var target = event.target;

                        var x = (parseFloat(target.getAttribute('x')) || 0);
                        var y = (parseFloat(target.getAttribute('y')) || 0);

                        target.setAttribute('width', event.rect.width + 'px');
                        target.setAttribute('height', event.rect.height + 'px');

                        // translate when resizing from top or left edges
                        x += event.deltaRect.left;
                        y += event.deltaRect.top;

                        target.setAttribute('x', x);
                        target.setAttribute('y', y);
                        removeButton.move(x + event.rect.width - 5, y - 5);
                    }
                },
                modifiers: [
                    interact.modifiers.restrictEdges({
                        outer: 'parent'
                    }),
                    interact.modifiers.restrictSize({
                        min: { width: 20, height: 20 }
                    })
                ]
            })
            .draggable({
                listeners: {
                    start (event) {
                        var _rect = event.target.instance;
                        _rect.fire('select');
                    },
                    move (event) {
                        var target = event.target;

                        var x = (parseFloat(target.getAttribute('x')) || 0) + event.dx;
                        var y = (parseFloat(target.getAttribute('y')) || 0) + event.dy;
                        target.setAttribute('x', x);
                        target.setAttribute('y', y);
                        removeButton.move(x + parseFloat(target.getAttribute('width') || 0) - 5, y - 5);
                    }
                },
                modifiers: [
                    interact.modifiers.restrictRect({
                        restriction: 'parent',
                    })
                ],
                cursorChecker: (action, interactable, element, interacting) => {
                    switch (action.axis) {
                        case 'x': return 'ew-resize'
                        case 'y': return 'ns-resize'
                        default: return interacting ? 'grabbing' : 'move'
                    }
                }
            });
    }

    rect.data('disabled', disabled);
    rect.data('crop', true);

    return rect;
}

export function formatBase64(image, options = {removeHeader: false}) {
    if (image.startsWith('data:')) {
        if (options.removeHeader) {
            return image.split(',')[1];
        } else {
            return image;
        }
    } else if (options.removeHeader) {
        return image;
    } else {
        return 'data:image/png;base64, ' + image;
    }
}
